import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

interface Response{
    data:[{
        id: number;
        tipo: string;
    }],
}
interface ResponseEmpresa{
    data:[{
        id: number;
        empresa: string;
    }],
}

export async function getDocumentoTipos(): Promise<Response>{
    var config:object = {
        method: 'get',
        url: `${API_URL}/documentos/tipos`,
        headers: { 
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        }
    };

    try{
        const resp = await axios(config);
        return {
            data: resp.data.data,
        };
    }catch(error){ 
        throw new Error(error);
    }
}

export async function getEmpresas(): Promise<ResponseEmpresa>{
    const subconta = JSON.parse(localStorage.getItem('@auth:subconta') || "");
    var config:object = {
        method: 'get',
        url: `${API_URL}/empresas?subconta=${subconta.id}`,
        headers: { 
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        }
    };

    try{
        const resp = await axios(config);
        return {
            data: resp.data.data,
        };
    }catch(error){ 
        throw new Error(error);
    }
}

export async function getSubcontas(): Promise<any>{
    var config:object = {
        method: 'get',
        url: `${API_URL}/subcontas`,
        headers: { 
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        }
    };

    try{
        const resp = await axios(config);
        return {
            data: resp.data.data,
        };
    }catch(error){ 
        throw new Error(error);
    }
}

export async function getPerfil(token?:string): Promise<any>{
    var config:object = {
        method: 'get',
        url: `${API_URL}/auth/perfil`,
        headers: { 
            'Accept': 'application/json',
            'Authorization': `Bearer ${token || localStorage.getItem('@auth:token')}`
        }
    };

    try{
        const resp = await axios(config);
        return resp.data.data;
    }catch(error){ 
        throw error.response.data;
    }
}

export async function editPerfil(data:object): Promise<any>{
    var config:object = {
        method: 'put',
        url: `${API_URL}/auth/perfil`,
        headers: { 
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        },
        data
    };

    try{
        await axios(config);
        return{
            message: "OK"
        };
    }catch(error){ 
        throw error.response.data.errors;
    }
}

