import { IonCard, IonCardContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useState } from 'react';
import ButtonBasic from './ButtonBasic';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi';

import '../assets/css/components/card-bill.css';

interface CardBillProps{
    status: string,
    valor: number,
    vencimento: string,
    data: string
}

const CardBill: React.FC<CardBillProps> = (props) => {

    const [opened, setOpened] = useState(false);

    return(
        <IonCard className={(opened ? 'card-bill height-grande' : 'card-bill') + (props.status === 'pago' ? ' card-pago' : '')}>
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol offset="1" size="9" className="primary-font">
                            <h5 className="text-black font-1-1">Mensalidade <strong>{props.data}</strong></h5>
                            <span className={"primary-font label-status font-extra-small text-white bold "+ props.status}>
                                <strong>{(props.status).replace('-',' ').toLocaleUpperCase()}</strong>
                            </span>
                        </IonCol>
                        <IonCol size="1" className="flex justify-center align-center font-1-2">
                            <button onClick={() => setOpened(!opened)} className={"button-action font-1-2 text-aster-red-dark flex justify-center align-center" + (props.status === 'pago' ? ' showmore-pago' : '')}>{ opened ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}</button>
                        </IonCol>
                    </IonRow>
                    <IonRow style={{'marginTop':'20px'}}>
                        <IonCol size="5" offset="1">
                            <p className="primary-font text-aster-red font-medium">Vencimento:</p>
                        </IonCol>
                        <IonCol size="5">
                            <p className="primary-font text-aster-red font-medium">{props.vencimento}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="5" offset="1">
                            <p className="primary-regular text-green font-medium">Valor:</p>
                        </IonCol>
                        <IonCol size="5">
                            <p className="primary-regular text-green font-medium">R${props.valor}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="10" offset="1">
                            <ButtonBasic onclick={() => {}} text="GERAR 2º VIA"/>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

export default CardBill