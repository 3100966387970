import { 
    IonContent,
    IonPage,
    IonSlides,
    IonSlide,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonSpinner
} from '@ionic/react';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { FaRegBuilding } from 'react-icons/fa';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Redirect } from 'react-router-dom';
import Logo from './../assets/images/logored.png';

import { getSubcontas } from './../services/helper'
import AuthContext from './../contexts/auth';
//import { allowedNodeEnvironmentFlags } from 'process';

const slideOpts = {
    initialSlide: 0,
    speed: 400,
    direction: 'horizontal',
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 1
        },
        768: {
            slidesPerView: 2
        },
        1024: {
            slidesPerView: 2
        }
    }
};

function Subcontas() {

    const [subcontas, setSubcontas] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const { signOut, fetchRecursos } = useContext(AuthContext);

    const slidesRef = useRef<HTMLIonSlidesElement>(null);
    const handleNext = () => slidesRef.current?.slideNext();
    const handlePrev = () => slidesRef.current?.slidePrev();

    function selectSubconta(id:number){
        let sub = subcontas.filter(item => {
            return item.id === id
        })

        localStorage.setItem('@auth:subconta',JSON.stringify(sub[0]));
        fetchRecursos();
        setRedirect(true);
    }

    useEffect(() => {
        async function loadSubcontas(){
            setLoading(true);
            const resp = await getSubcontas();
            
            setSubcontas( resp.data );
            setLoading(false);
        }
        loadSubcontas();
    }, [])

    function sair(){
        signOut();
    }



    if(redirect)
        return <Redirect to={{pathname:'/'}}/>
    else if(  subcontas.length === 1 ){
        localStorage.setItem('@auth:subconta_unica', JSON.stringify(true));
        selectSubconta( subcontas[0].id );
    }

    return (
        <IonPage>
            <IonContent fullscreen className="ion-justify-content-center ion-align-items-center">
                <IonGrid>
                    <IonRow className="ion-justify-content-center ion-align-items-center">
                        <IonCol style={{marginTop: '50px'}} size="8" sizeMd="6" sizeLg="2">
                            <img style={{width:'100%', height:'100%'}} src={Logo} alt="Logo"/>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-align-items-center">
                        <IonCol size="10">
                            <h3 className="primary-regular text-gray ion-text-center">Selecione uma unidade para entrar</h3>
                        </IonCol>
                    </IonRow >
                    <IonRow className="ion-text-center ion-justify-content-center ion-align-items-center">
                        <IonCol size="10">
                            {(!loading && subcontas.length === 0) && <h3 style={{marginTop: '50px'}}  className="primary-regular text-black ion-text-center">Não foi encontrada nenhuma unidade vinculada. Contate o administrador para ajustar o seu cadastro.</h3>}
                            {loading && <IonSpinner name="crescent" color="aster-primary"/>}
                            {
                                subcontas.length > 0 &&
                                    <>
                                        <IonSlides ref={slidesRef} pager={true} options={slideOpts} className="ion-align-items-center">
                                            {subcontas.map( (subconta:any, index) => {
                                                return(
                                                    <IonSlide key={index}>
                                                        <IonCard mode="ios" style={{borderTop: '5px solid #C4151C'}}>
                                                            <IonCardHeader>
                                                                <div style={{padding:'20px'}}>
                                                                    <FaRegBuilding style={{fontSize: '2.5rem'}} />
                                                                </div>
                                                                <IonCardTitle className="primary-font text-aster-red">{subconta.conta}</IonCardTitle>
                                                            </IonCardHeader>

                                                            <IonCardContent className="primary-light">
                                                                {subconta.descricao}
                                                                <div style={{padding:'20px'}}>
                                                                    <button onClick={() => selectSubconta(subconta.id)} className="btn-select-subconta primary-font text-gray">ENTRAR</button>
                                                                </div>
                                                            </IonCardContent>
                                                        </IonCard>
                                                    </IonSlide>
                                                )
                                            })}
                                        </IonSlides>
                                        <div className="slide-arrow prev" onClick={handlePrev}><BsChevronLeft /></div>
                                        <div className="slide-arrow next" onClick={handleNext}><BsChevronRight /></div>
                                    </>
                            }
                        </IonCol>
                        <IonCol size="12">
                            <button 
                                style={{
                                    padding: '10px',
                                    border: '2px solid #C4151C',
                                    backgroundColor: 'transparent'
                                }}
                                className="primary-regular text-aster-red"
                                onClick={sair}
                            >
                                SAIR
                            </button>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default Subcontas
