import React from 'react';
import {IonContent, IonRow, IonCol, IonPage} from '@ionic/react';
import Navbar from "../components/Navbar";
import {IoIosPaper} from "react-icons/io";
import {FooterExample} from "../components/Footer";

export const TermosUso: React.FC = () => ( 
    <IonPage className="common-pages">
        <IonContent>
            <Navbar></Navbar>
            <div className="page-title-container">
                <div className="page-title justify-space-between">
                    <div className="flex justify-left">
                        <span className="font-big text-aster-red bold"><IoIosPaper/></span>
                        <span
                            className="title-text primary-font font-1-2 text-aster-red bold">TERMOS E CONDIÇÕES DE USO</span>
                    </div>
                </div>
            </div>

            <IonRow>
                <IonCol>
                    <div className="primary-font" style={{
                        color: "#626262",
                        width: "70%",
                        margin: "auto",
                        padding: "10px"
                    }}>
                        <p><b>Olá, seja bem-vindo à Plataforma ASTER! Agradecemos seu
                            acesso.</b></p>
                        <p>
                            Estes são os Termos e Condições de Uso (“Termos”) que regram
                            a utilização e o acesso dos produtos e serviços
                            disponibilizados (“Serviços”) pela ASTER SISTEMAS DE
                            SEGURANÇA LTDA. (“ASTER”), com sede na Av. Dr. Arnaldo,
                            2.405, Sumaré, São Paulo – SP, CEP 01255-000, inscrita no
                            CNPJ/MF sob o nº 02.428.200/0001-75; ASTER SISTEMAS DE
                            SERVIÇOS LTDA. (“ASTER”), com sede na Rua Cayowaa, 759, 8º
                            andar, Perdizes, São Paulo, SP, CEP 05018-001, inscrita no
                            CNPJ/MF sob o nº 06.165.275/0001-61 e Asterseg Eletronica
                            Ltda. (“ASTER”), com sede na Rua Cayowaa, 759, 8º andar,
                            Perdizes, São Paulo, SP, CEP 05018-001, inscrita no CNPJ/MF
                            sob o nº 10.611.620/0001-10, através da “Plataforma ASTER”,
                            que inclui o aplicativo, página da Internet e conteúdos
                            relacionados, a toda e qualquer pessoa como Você, que
                            utilize os Serviços, a seguir denominado simplesmente
                            “Usuário(s)”.
                        </p>
                        <p>
                            1. A ASTER entende e trabalha duro para garantir a melhor
                            prestação de serviços com transparência, segurança e
                            tranquilidade.
                        </p>
                        <p>
                            2. O acesso à Plataforma ASTER possibilita a Você, Usuário,
                            ter acesso aos nossos produtos e serviços e suas respectivas
                            funcionalidades e ao final decidir pela efetiva utilização
                            ou não desses serviços prestados pela ASTER.
                        </p>
                        <p>
                            3. Antes de utilizar e navegar pela Plataforma, é
                            fundamental que Você, Usuário, leia e entenda esses Termos,
                            devendo acessar também nossa <a rel="noopener noreferrer" href="/politica-de-privacidade">Política de
                            Privacidade</a> que faz parte integrante e
                            indissociável destes Termos.
                        </p>
                        <p>
                            4. Caso tenha quaisquer dúvidas ou preocupações, a ASTER
                            está à disposição para atendê-lo da melhor forma através de
                            nossos canais de comunicação: e-mail: sac@aster.com.br;
                            WhatsApp: <a target="_blank" rel="noopener noreferrer" href="https://wa.me/5511989062810">(11) 96603-5822</a>.
                        </p>
                        <p>
                            5. Ao clicar no botão “Eu li e aceito”, Você, Usuário,
                            concorda e expressa sua vontade livre, consciente e
                            informada de cumprir com estes Termos e a Política de
                            Privacidade.
                        </p>
                        <p>
                            6. Importante esclarecer que a decisão de aceitação ou não
                            destes Termos é sempre do Usuário, de forma que a sua não
                            aceitação implicará na impossibilidade da utilização da
                            Plataforma ASTER. De igual forma, a qualquer tempo, Você,
                            Usuário, poderá optar pela descontinuidade de seu cadastro
                            e/ou do uso da Plataforma.
                        </p>
                        <p>
                            7. A ASTER terá o direito, a qualquer momento e a seu
                            exclusivo critério, de modificar, alterar, adicionar ou
                            remover qualquer diretriz ou cláusula contida nestes Termos
                            ou em qualquer documento a eles relacionados, sem que seja
                            necessário o aviso prévio aos Usuários. Quaisquer alterações
                            serão devidamente informadas, sendo sempre indicada a data
                            da última atualização realizada nos Termos, cabendo a Você,
                            Usuário certificar-se rotineiramente, preferencialmente,
                            sempre que acessar a Plataforma, sobre eventuais
                            atualizações. Assim que publicada a alteração destes Termos,
                            a aceitação e o uso destes passa a ser submetido
                            automaticamente. Ocorrendo atualizações neste documento e
                            que demandem nova coleta de consentimento, Você, Usuário,
                            será notificado por meio dos contatos que fornecidos no
                            cadastro.
                        </p>
                        <p>
                            8. A utilização da Plataforma ASTER e todas suas
                            funcionalidades estará restrita aos Usuários que tiverem
                            capacidade civil e poderes legais para celebrarem um
                            contrato vinculativo com a ASTER, devendo comprometer-se que
                            todas as informações enviadas para a ASTER são verdadeiras,
                            completas, precisas e atualizadas.
                        </p>
                        <p>
                            9. A ASTER não se responsabiliza pelo acesso indevido de
                            menores de idade e/ou pessoas sem poderes legais para
                            acessar nossas páginas em razão de informações incorretas ou
                            inverídicas cadastradas na Plataforma ASTER.
                        </p>
                        <p>
                            10. Através da Plataforma ASTER, Você, Usuário poderá
                            visualizar os serviços disponíveis, solicitar informações e
                            orçamentos, e, após a contratação, utilizara gama de
                            serviços fornecidos, efetuar reclamações, dentre outras
                            funcionalidades.
                        </p>
                        <p>
                            11. A utilização da Plataforma ASTER poderá ser gratuita ou
                            onerosa, dependendo da finalidade desejada.
                            Independentemente da modalidade contratada, não será
                            disponibilizada qualquer garantia, seja implícita ou
                            explicita, de qualquer natureza, em relação ao seu
                            funcionamento e/ou a disponibilidade de informações.
                        </p>
                        <p>
                            12. A utilização da Plataforma ASTER poderá exigir o
                            cadastro de informações pessoais as quais serão devidamente
                            protegidas e utilizadas conforme <a rel="noopener noreferrer" href="/politica-de-privacidade">Política de Privacidade da
                            ASTER</a>, que faz parte integrante dos
                            presentes Termos, e em conformidade com o ordenamento
                            jurídico vigente. Em determinadas situações, será necessário
                            o cadastramento de um login e senha para utilização da
                            Plataforma ASTER. Estas informações serão estritamente
                            pessoais e de responsabilidade exclusiva do Usuário, que
                            deverá mantê-las em segredo, não podendo disponibilizá-la a
                            terceiros.
                        </p>
                        <p>
                            13. Caso o Usuário tenha ciência e/ou desconfiança que suas
                            informações de login e senha foram reveladas, descobertas
                            por terceiros ou que estejam sendo utilizados de forma
                            indevida, o Usuário deverá realizar a imediata alteração de
                            tais dados.
                        </p>
                        <p>
                            14. A Plataforma ASTER pode possuir links para outras
                            plataformas (terceiros) e, sobre o uso destas plataformas de
                            terceiros, não terá qualquer responsabilidade. A
                            responsabilidade pela utilização de plataformas terceiras
                            serão exclusivas do próprio Usuário.
                        </p>
                        <p>
                            15. A utilização da plataforma em desconformidade com estes
                            Termos, com a Política de Privacidade da ASTER e/ou com a
                            normatização vigente, poderá ensejar o encerramento de login
                            e senha e a impossibilidade de acesso.
                        </p>
                        <p>
                            16. A ASTER poderá, a qualquer tempo, sem qualquer motivação
                            explicita, encerrar e/ou interromper o acesso à Plataformas
                            ASTER, sem que isto implique qualquer responsabilidade por
                            eventuais danos.
                        </p>
                        <p>
                            17. A ASTER não será responsável, em nenhuma hipótese, por
                            eventuais danos, atrasos, falhas decorrentes da mora,
                            bloqueio e/ou interrupção nas transmissões de dados,
                            independentemente de sua causa, ainda que venha a ocorrer
                            por culpa de terceiros ou não, principalmente no tocante à
                            conexão de internet do Usuário.
                        </p>
                        <p>
                            18. A ASTER poderá coletar e tratar informações de navegação
                            por meio da utilização de tecnologias e metodologias como
                            cookies que permitam rastrear ou fornecer outras informações
                            sobre sua interação com a Internet.
                        </p>
                        <p>
                            19. Para fins destes Termos, cookies são pequenos bites de
                            informação armazenados no computador pelo navegador da
                            Internet. Os cookies ajudam a estabelecer uma sessão de
                            Usuário e permitir que nosso servidor forneça informações,
                            anúncios e/ou serviços apropriados aos Usuários.
                        </p>
                        <p>
                            20. O Usuário está ciente de que os direitos sobre todos os
                            componentes e conteúdos da Plataforma ASTER, tais como
                            marcas, layout, arquivos de dados, elementos gráficos,
                            programas, arquivos de áudio, fotografias, vídeos, imagens,
                            logotipos, marcas, expertise, entre outros, são de
                            propriedade exclusiva da ASTER.
                        </p>
                        <p>
                            21. A Plataforma poderá, ainda, conter conteúdos, marcas,
                            elementos gráficos e outros de terceiros, que serão os
                            legítimos proprietários destas, sem que isso represente
                            qualquer cessão de direitos aos Usuários.
                        </p>
                        <p>
                            22. O Usuário não poderá alterar, no todo ou em parte,
                            qualquer conteúdo da Plataforma ou do próprio sistema
                            tecnológico da ASTER, tão pouco transmitir, reproduzir,
                            reutilizar, publicar, divulgar sem autorização expressa da
                            ASTER, respondendo nos limites da lei civil e penal pelo
                            descumprimento destes Termos
                        </p>
                        <p>
                            23. O Usuário, após concordar com estes Termos assumirá
                            todos os riscos de utilização da Plataforma, não sendo a
                            ASTER, em hipótese alguma, responsável por eventuais
                            prejuízos sofridos pelo Usuário e/ou terceiros, seja de
                            natureza penal, civil, econômica, moral, bem como qualquer
                            outra decorrentes de forma direta e/ou indireta do seu
                            próprio uso e experiências da Plataforma ASTER.
                        </p>
                        <p>
                            24. A ASTER também não será responsabilizada, em nenhuma
                            hipótese, em decorrência de atos que decorram de.
                        </p>
                        <ol type="a">
                            <li>
                                Informações erradas, falsas, incompletas dadas por
                                Usuários;
                            </li>
                            <li>
                                Infrações legais e infrações a estes Termos por parte
                                do
                                Usuário e/ou terceiros;
                            </li>
                            <li>
                                Mau uso da plataforma;
                            </li>
                            <li>
                                Falhas, interrupções, bloqueios na transmissão de
                                dados
                                pela internet;
                            </li>
                            <li>
                                Falhas e vulnerabilidades dos equipamentos utilizados
                                para o acesso às plataformas;
                            </li>
                            <li>
                                Infrações à honra, intimidade, privacidade cometidos
                                por
                                Usuários e/ou terceiros;
                            </li>
                            <li>
                                Qualquer ação do Usuário e/ou terceiros que venham a
                                desrespeitar a normatização vigente e os termos deste
                                instrumento;
                            </li>
                            <li>
                                Força maior e/ou caso fortuito;
                            </li>
                            <li>
                                Alterações, interrupções, bloqueios e extinção de
                                conteúdos da Plataforma ou até mesmo da própria
                                Plataforma.
                            </li>
                        </ol>
                        <p>
                            25. Os presentes Termos entram em vigor a partir da
                            aceitação pelos Usuários, permanecendo vigente por tempo
                            indeterminado: (i) até encerramento dos Serviços da
                            Plataforma ASTER; (ii) até o momento no qual os Usuários
                            manifestem expressamente discordância com suas disposições;
                            ou, (iii) até que a ASTER suspenda os seus respectivos
                            acessos.
                        </p>
                        <p>
                            26. Caso alguma disposição destes Termos seja considerada
                            ilegal, nula ou inexequível por qualquer razão, as demais
                            condições permanecerão em pleno vigor e efeito.
                        </p>
                        <p>
                            27. A tolerância de uma parte para com a outra quanto ao
                            descumprimento de qualquer uma das obrigações assumidas
                            neste contrato não implicará em novação ou renúncia de
                            direito. A parte tolerante poderá, a qualquer tempo, exigir
                            da outra parte o fiel e cabal cumprimento deste contrato.
                        </p>
                        <p>
                            28. O Usuário reconhece que toda comunicação realizada por
                            e-mail (aos endereços informados no seu cadastro), SMS,
                            aplicativos de comunicação instantânea ou qualquer outra
                            forma digital, virtual e digital também são válidas,
                            eficazes e suficiente para a divulgação de qualquer assunto
                            que se refira aos serviços prestados, bem como às condições
                            de sua prestação ou a qualquer outro assunto nele abordado,
                            ressalvadas as disposições expressamente diversas previstas
                            nestes Termos.
                        </p>
                        <p>
                            29. A ASTER declara expressamente, para os devidos fins, que
                            cumpre com todas as Leis atinentes à proteção de dados, como
                            a Constituição Federal de 1988, o Código Civil de 2002, o
                            Código de Defesa do Consumidor, a Consolidação das Leis do
                            Trabalho, o Marco Civil da Internet e seu Decreto
                            Regulamentador, a Lei Geral de Proteção de Dados Pessoais e
                            demais normas atinentes ao tema.
                        </p>
                        <p>
                            30. Estes Termos serão regidos, interpretados e executados
                            de acordo com as leis da República Federativa do Brasil,
                            sendo eleito o Foro da Comarca de São Paulo para dirimir
                            todas e quaisquer questões porventura existentes ou que
                            venham a existir entre elas, com expressa renúncia a
                            qualquer outro, por mais privilegiado que seja.
                        </p>
                        <p>
                            31. Qualquer dúvida, reclamação, solicitação, denúncia e
                            sugestão em relação a estes Termos e à Plataforma ASTER
                            poderáo ser efetuadas através dos nossos canais de
                            comunicação: e-mail sac@aster.com.br e WhatsApp: <a target="_blank" rel="noopener noreferrer" href="https://wa.me/5511989062810">(11) 96603-5822</a>.
                        </p>
                        <p>
                            DECLARO, na qualidade de Usuário, estar ciente dos direitos
                            e obrigações decorrentes destes Termos e Condições de Uso e
                            da Política de Privacidade deles integrantes, tendo lido,
                            compreendido e aceito todas as disposições, tratando-se de
                            acordo com aceite eletrônico, para o qual reconheço desde já
                            a autenticidade e validade.
                        </p>

                    </div>
                </IonCol>
            </IonRow>
            <FooterExample></FooterExample>
        </IonContent>
    </IonPage>
);
