import React from 'react';

import '../assets/css/components/button-form.css'

const ButtonForm: React.FC<{text:string, onclick: Function}> = (props) => {
    return(
        <>
            <button onClick={() => props.onclick()} className="btn-form font-1-1 primary-regular">{props.text}</button>
        </>
    )
}

export default ButtonForm