import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import React, {useState} from 'react';
import Navbar from '../components/Navbar';
import TextField from '../components/TextField';
import ButtonForm from '../components/ButtonForm';

import '../assets/css/abertura-chamados.css';


const AberturaChamados: React.FC = () => {

    const [nome, setNome] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [telefone, setTelefone] = useState<string>("");
    const [celular, setCelular] = useState<string>("");
    const [motivo, setMotivo] = useState<string>("");

    return(
        <IonPage className="abertura-chamados">
            <IonContent fullscreen>
                <Navbar />
                <IonGrid>
                    <IonRow>
                        <IonCol size="10" offset="1">
                            <TextField onchange={(value:string) => setNome(value)} value={nome} label="Nome" type="text" name="nome" placeholder="Digite aqui..." />
                        </IonCol>
                        <IonCol size="10" offset="1">
                            <TextField onchange={(value:string) => setEmail(value)} value={email} label="E-mail" type="email" name="email" placeholder="Digite aqui..." />
                        </IonCol>
                        <IonCol size="5" offset="1">
                            <TextField onchange={(value:string) => setTelefone(value)} value={telefone} label="Telefone" type="text" name="telefone" placeholder="Digite aqui..." />
                        </IonCol>
                        <IonCol size="5">
                            <TextField onchange={(value:string) => setCelular(value)} value={celular} label="Celular" type="text" name="celular" placeholder="Digite aqui..." />
                        </IonCol>
                        <IonCol size="10" offset="1">
                            <TextField onchange={(value:string) => setMotivo(value)} value={motivo} label="Motivo da abertura do chamado" type="text" name="motivo-abertura" placeholder="Digite aqui..." />
                        </IonCol>
                        <IonCol size="10" offset="1">
                            <ButtonForm onclick={() => {}} text="Solicitar abertura" />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
      </IonPage>
    )
}

export default AberturaChamados;