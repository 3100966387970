import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

interface Person{
    data:[{
        id: number;
        nome: string;
        ativo: number;
        cadastro_tipo: {
            id: number;
            tipo: string;
        };
    }],
    last_page: number;
}

export async function getPeople(page:number):Promise<Person>{
    const subconta = JSON.parse(localStorage.getItem('@auth:subconta') || "");
    var config:object = {
        method: 'get',
        url: `${API_URL}/pessoas?page=${page}&subconta=${subconta.id}`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        }
    };

    try{
        const req = await axios(config);
        return {
            data: req.data.data,
            last_page: req.data.meta.last_page
        };
    }catch(error){ 
        throw new Error(error);
    }
}

export async function getPersonById(id:number):Promise<any>{
    const subconta = JSON.parse(localStorage.getItem('@auth:subconta') || "");
    var config:object = {
        method: 'get',
        url: `${API_URL}/pessoas/${id}/editar?subconta=${subconta.id}`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        }
    };

    try{
        const req = await axios(config);
        return req.data.data;
    }catch(error){ 
        throw new Error(error);
    }
}

export async function registerPerson(data:object):Promise<{message:string}>{
    var config:object = {
        method: 'post',
        url: `${API_URL}/pessoas`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        },
        data: data
    };

    try{
        await axios(config);
        return{
            message: "OK"
        };
    }catch(error){ 
        // console.log(error.response)
        throw error.response.data.errors;
    }
}

export async function editPerson(data:object, id:number):Promise<{message:string}>{
    var config:object = {
        method: 'put',
        url: `${API_URL}/pessoas/${id}`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        },
        data: data
    };

    try{
        await axios(config);
        return{
            message: "OK"
        };
    }catch(error){ 
        // console.log(error.response)
        throw error.response.data.errors;
    }
}

export async function deletePerson(id:number):Promise<{success: boolean}>{

    const subconta = JSON.parse(localStorage.getItem('@auth:subconta') || "");

    var config:object = {
        method: 'delete',
        url: `${API_URL}/pessoas/${id}?subconta=${subconta.id}`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        }
    };
    try{
        const resp = await axios(config);
        return {
            success: resp.data.success
        };
    }catch(error){ 
        throw new Error(error);
    }
}