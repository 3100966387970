import axios from "axios";
import moment from "moment";
const API_URL = process.env.REACT_APP_API_URL;

interface Response {
  token: string;
  user: {
    nome: string;
    email: string;
    cadastro_tipo: {
      id: number;
      tipo: string;
    };
    sub_conta: any;
    acesso_app_meu_bairro: number;
  };
  expires_in: string;
}

export function signIn(email: string, password: string): Promise<Response> {
  moment.locale("pt-br");

  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/auth/login`, {
        email: email,
        password: password,
      })
      .then(function (response) {
        // console.log(response);
        let expires_in = moment()
          .add(response.data.expires_in, "seconds")
          .format();

        resolve({
          token: response.data.access_token,
          user: {
            nome: response.data.user.nome,
            email: response.data.user.email,
            cadastro_tipo: response.data.user.cadastro_tipo,
            sub_conta: response.data.user.sub_conta,
            acesso_app_meu_bairro: response.data.user.acesso_app_meu_bairro,
          },
          expires_in: expires_in,
        });
      })
      .catch(function (error) {
        // console.log(error);

        reject(error);
      });
  });
}

export function signInSSO(
  searchString: string,
  provider: string
): Promise<Response> {
  moment.locale("pt-br");

  let headers = {};

  if (localStorage.getItem("@auth:token")) {
    headers = {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("@auth:token")}`,
    };
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/auth/login/${provider}/callback${searchString}`, {
        headers: headers,
      })
      .then(function (response) {
        let expires_in = moment()
          .add(response.data.expires_in, "seconds")
          .format();

        resolve({
          token: response.data.access_token,
          user: {
            nome: response.data.user.nome,
            email: response.data.user.email,
            cadastro_tipo: response.data.user.cadastro_tipo,
            sub_conta: response.data.user.sub_conta,
            acesso_app_meu_bairro: response.data.user.acesso_app_meu_bairro
          },
          expires_in: expires_in,
        });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function updateToken(): Promise<Response> {
  moment.locale("pt-br");

  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: `${API_URL}/auth/atualizar`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("@auth:token")}`,
      },
    })
      .then(function (response) {
        let expires_in = moment()
          .add(response.data.expires_in, "seconds")
          .format();

        resolve({
          token: response.data.access_token,
          user: {
            nome: response.data.user.nome,
            email: response.data.user.email,
            cadastro_tipo: response.data.user.cadastro_tipo,
            sub_conta: response.data.user.sub_conta,
            acesso_app_meu_bairro: response.data.user.acesso_app_meu_bairro
          },
          expires_in: expires_in,
        });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function sendForgotPasswordEmail(email: string) {
  var config: object = {
    method: "post",
    url: `${API_URL}/auth/esqueci-senha`,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("@auth:token")}`,
    },
    data: {
      email: email,
    },
  };

  try {
    const resp = await axios(config);
    return resp.data;
  } catch (error) {
    throw error.response.data.errors;
  }
}

export async function recoverPassword(data: object) {
  var config: object = {
    method: "post",
    url: `${API_URL}/auth/recuperar-senha`,
    headers: {
      Accept: "application/json",
    },
    data: data,
  };

  try {
    const resp = await axios(config);
    return resp.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

export async function portalRecursos() {
  const subconta = JSON.parse(localStorage.getItem('@auth:subconta') || "");
    var config:object = {
        method: 'get',
        url: `${API_URL}/portal-recursos?subconta=${subconta.id}`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        }
    };

    try{
        const resp = await axios(config);
        return {
            data: resp.data.data,
        };
    }catch(error){ 
        throw new Error(error);
    }
}
