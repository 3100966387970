import React from 'react';
import {HiOutlineArrowRight} from 'react-icons/hi'

import '../assets/css/components/button-arrow.css'

const ButtonArrow: React.FC<{text:string,onclick?:Function}> = (props) => {
    return(
        <>
            <button onClick={() => {if(props.onclick) props.onclick()}} className="btn-arrow primary-font">
                <div className="text-button">
                    {props.text}
                </div>
                <div className="icone-opt ion-align-items-center ion-justify-content-center ion-text-center">
                    <HiOutlineArrowRight />
                </div>
            </button>
        </>
    )
}

export default ButtonArrow