import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useEffect, useContext, useState, useCallback } from "react";
import { IoMdPaper } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaRegSadTear, FaArrowLeft } from "react-icons/fa";
import moment from "moment";

import Navbar from "../components/Navbar";
import AuthContext from "./../contexts/auth";

import { getPagina, Pagina } from "../services/paginas";
import { useHistory, useParams } from "react-router";

const PaginaPage: React.FC = () => {
  moment.locale("pt-br");

  const [pagina, setPagina] = useState<Pagina | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const { setExpired, refreshToken } = useContext(AuthContext);
  const history = useHistory();
  const params = useParams<{ id?: string }>();

  const loadPagina = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await getPagina(Number(params.id));
      setPagina(resp);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  }, [params.id]);

  // Monitor the page state, every time page changes this function get the current page from api
  useEffect(() => {
    const expire_date = new Date(
      localStorage.getItem("@auth:expires_in") || ""
    );
    const now = new Date(moment().format());
    if (now.getTime() > expire_date.getTime()) {
      console.log("Expirou");
      setExpired();
    } else {
      loadPagina();
    }
  }, [loadPagina, refreshToken, setExpired]);

  return (
    <IonPage className="common-pages">
      <IonContent fullscreen scrollEvents={true}>
        <Navbar />
        <div className="page-title-container">
          <div className="page-title justify-space-between">
            <div className="flex justify-left">
              <span className="font-big text-aster-red bold">
                <IoMdPaper />
              </span>&nbsp;
              <span className="title-text primary-font font-1-2 text-aster-red bold">
                Páginas
              </span>
            </div>
          </div>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="10" sizeXl="8" offsetMd="1" offsetXl="2">
              <button
                className="font-1-1 text-aster-red flex justify-center align-center"
                style={{ background: "none", marginTop: '15px' }}
                onClick={() => history.push('/paginas')}
              >
                <FaArrowLeft />
              </button>
            </IonCol>
          </IonRow>
          {pagina && (
            <>
              <IonRow>
                <IonCol
                  size="12"
                  sizeMd="10"
                  sizeXl="8"
                  offsetMd="1"
                  offsetXl="2"
                >
                  <h1 className="title-text primary-font text-aster-red bold">
                    {pagina.titulo}
                  </h1>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  size="12"
                  sizeMd="10"
                  sizeXl="8"
                  offsetMd="1"
                  offsetXl="2"
                >
                  <div dangerouslySetInnerHTML={{ __html: pagina.texto }} />
                </IonCol>
              </IonRow>
            </>
          )}

          <IonRow>
            <IonCol size="12" sizeMd="10" sizeXl="8" offsetMd="1" offsetXl="2">
              {!pagina && !loading && (
                <h1 className="primary-font text-aster-red font-medium ion-text-center">
                  Nenhum resultado encontrado.
                </h1>
              )}
              {loading && (
                <h5 className="primary-font text-aster-red font-1-3 ion-text-center">
                  <AiOutlineLoading3Quarters className="spin" />
                </h5>
              )}
              {error && (
                <>
                  <h5 className="primary-font text-aster-red font-1-4 ion-text-center">
                    <FaRegSadTear />
                  </h5>
                  <h5 className="primary-font text-aster-red font-medium ion-text-center">
                    Oops! Houve um erro no carregamento.
                  </h5>
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PaginaPage;
