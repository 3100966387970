import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import React, { useEffect, useContext } from 'react';
import Navbar from '../components/Navbar';
import CardBill from '../components/CardBill';
import { FaBarcode } from 'react-icons/fa';
import moment from 'moment';

import '../assets/css/boletos.css';

import AuthContext from './../contexts/auth';


const mensalidades = [
    {
        data: 'jul/2020',
        status: 'expirado',
        vencimento: '10/07/2020',
        valor: 850
    },
    {
        data: 'jun/2020',
        status: 'a-vencer',
        vencimento: '10/06/2020',
        valor: 850
    },
    {
        data: 'jun/2020',
        status: 'pago',
        vencimento: '10/06/2020',
        valor: 850
    },
    {
        data: 'jun/2020',
        status: 'pago',
        vencimento: '10/06/2020',
        valor: 850
    },
    {
        data: 'mai/2020',
        status: 'a-vencer',
        vencimento: '10/05/2020',
        valor: 900
    },
    {
        data: 'mai/2020',
        status: 'a-vencer',
        vencimento: '10/05/2020',
        valor: 900
    },
    {
        data: 'mai/2020',
        status: 'a-vencer',
        vencimento: '10/05/2020',
        valor: 900
    },
    {
        data: 'mai/2020',
        status: 'a-vencer',
        vencimento: '10/05/2020',
        valor: 900
    },
    {
        data: 'mai/2020',
        status: 'a-vencer',
        vencimento: '10/05/2020',
        valor: 900
    },
]

const Boletos: React.FC = () => {
    moment.locale('pt-br')

    const { setExpired } = useContext(AuthContext)

    useEffect(() => {
        const expire_date = new Date(localStorage.getItem('@auth:expires_in') || "");
        const now = new Date(moment().format());
        if(now.getTime() > expire_date.getTime()){
            console.log("Expirou")
            setExpired();
        }
    }, [setExpired])

    return(
        <IonPage className="common-pages">
            <IonContent fullscreen>
                <Navbar />
                <div className="page-title-container">
                    <div className="page-title justify-left">
                        <span className="font-big text-aster-red bold"><FaBarcode/></span>&nbsp;
                        <span className="title-text primary-font font-1-2 text-aster-red bold">Boletos</span>
                    </div>
                </div>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" sizeMd="10" sizeXl="8" offsetMd="1" offsetXl="2">
                            <ul className="container-bill-card">
                        {
                            mensalidades.map((mensalidade, index) => {
                                return (
                                    
                                    <li key={index} >
                                        <CardBill 
                                            status={mensalidade.status}
                                            valor={mensalidade.valor} 
                                            vencimento={mensalidade.vencimento}
                                            data={mensalidade.data}
                                        />
                                    </li>
                                )
                            })
                        }
                            </ul>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
      </IonPage>
    )
}

export default Boletos;