import React from 'react';

import '../assets/css/components/radio.css';

interface RadioProps {
    label: string;
    name: string;
    value: string;
    checked: boolean;
    onchange: Function
}

const Radio: React.FC<RadioProps> = (props) => {
    return(
        <>
            <label className="radio">
                <input type="radio" onChange={() => props.onchange(props.value)} name={props.name} value={props.value} defaultChecked={props.checked} />
                <span>{props.label}</span>
            </label>
        </>
    )
}

export default Radio;