import {
  IonCol,
  IonContent,
  IonGrid,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
  IonToast,
} from "@ionic/react";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import moment from "moment";
import { VscChromeClose } from "react-icons/vsc";

import "../assets/css/perfil.css";
import DefaultUser from "./../assets/images/user-default.png";
import AuthContext from "./../contexts/auth";
import { editPerfil, getPerfil } from "./../services/helper";
import MaskedTextField from "../components/MaskedTextField";
import TextField from "../components/TextField";
import ButtonForm from "../components/ButtonForm";
import CheckboxBasic from "../components/CheckboxBasic";
import ButtonSSO from "../components/ButtonSSO";

interface Errors {
  nome: [];
  email: [];
  data_nascimento: [];
  password: [];
  password_confirm: Array<string>;
  cpf: [];
}

const Perfil: React.FC = () => {
  moment.locale("pt-br");

  const [dados, setDados] = useState<any>({});
  const [nome, setNome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const [confirmaSenha, setConfirmaSenha] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [celular, setCelular] = useState<string>("");
  const [dtNasc, setDtNasc] = useState<string>("");
  const [editErrors, setEditErrors] = useState({} as Errors);

  const [loading, setLoading] = useState<boolean>(true);
  const [showToastSuccess, setShowToastSuccess] = useState<boolean>(false);
  const [showModalEdicao, setShowModalEdicao] = useState<boolean>(false);
  const [showEdicaoSenha, setShowEdicaoSenha] = useState<boolean>(false);
  const [loadingEdicao, setLoadingEdicao] = useState<boolean>(false);
  const [desvincularFacebook, setDesvincularFacebook] =
    useState<boolean>(false);
  const [desvincularGoogle, setDesvincularGoogle] = useState<boolean>(false);
  const { user, setExpired, refreshToken } = useContext(AuthContext);

  async function loadPerfil() {
    setLoading(true);
    const data = await getPerfil();
    setDados(data);
    setNome(data.nome);
    setEmail(data.email);
    setCpf(data.cpf);
    setCelular(data.celular);
    setDtNasc(data.data_nascimento);
    setLoading(false);
  }

  function openModalEdicao(alterarSenha = false) {
    setShowEdicaoSenha(alterarSenha);
    setShowModalEdicao(true);
  }

  async function closeModalEdicao() {
    setShowModalEdicao(false);
    setShowEdicaoSenha(false);
    const data = await getPerfil();
    setNome(data.nome);
    setEmail(data.email);
    setSenha("");
    setConfirmaSenha("");
    setCpf(data.cpf);
    setCelular(data.celular);
    setDtNasc(data.data_nascimento);
  }

  async function editUser(e: FormEvent) {
    e.preventDefault();
    const data = {
      nome,
      email,
      password: senha,
      password_confirm: confirmaSenha,
      cpf,
      celular,
      data_nascimento: dtNasc,
      desvincular_facebook: desvincularFacebook ? 1 : 0,
      desvincular_google: desvincularGoogle ? 1 : 0,
    };
    setLoadingEdicao(true);
    if (senha !== confirmaSenha) {
      setEditErrors((prevErrors) => ({
        ...prevErrors,
        password_confirm: ["As senhas não coincidem"],
      }));
      document.querySelector(".content-modal-edicao")?.scrollTo(0, 0);
      setLoadingEdicao(false);
      return;
    }
    try {
      await editPerfil(data);
      closeModalEdicao();
      setLoadingEdicao(false);
      loadPerfil();
      setShowToastSuccess(true);
    } catch (error) {
      setEditErrors(error);
      document.querySelector(".content-modal-edicao")?.scrollTo(0, 0);
      setLoadingEdicao(false);
    }
  }

  useEffect(() => {
    const expire_date = new Date(
      localStorage.getItem("@auth:expires_in") || ""
    );
    const now = new Date(moment().format());
    if (now.getTime() > expire_date.getTime()) {
      console.log("Expirou");
      setExpired();
    } else {
      loadPerfil();
    }
  }, [refreshToken, setExpired]);

  return (
    <IonPage className="perfil">
      <IonContent fullscreen>
        <IonModal
          isOpen={showModalEdicao}
          cssClass="text-black"
          onDidDismiss={closeModalEdicao}
        >
          {loadingEdicao ? (
            <div className="loading-login">
              <IonSpinner name="crescent" color="danger" />
            </div>
          ) : (
            ""
          )}
          <div className="header-modal-edicao flex flex-row justify-space-between">
            <h1 className="primary-font text-white font-big">
              {showEdicaoSenha ? "Alterar senha" : "Editar dados"}
            </h1>
            <VscChromeClose
              style={{ marginTop: "10px" }}
              className="text-white font-big"
              onClick={() => setShowModalEdicao(false)}
            />
          </div>
          <div className="content-modal-edicao">
            <form onSubmit={(e: any) => editUser(e)} action="" method="post">
              <IonRow>
                <IonCol size="10" offset="1">
                  <div className="errors primary-font text-aster-red">
                    <ul>
                      {editErrors.nome &&
                        editErrors.nome.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      {editErrors.email &&
                        editErrors.email.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      {editErrors.password &&
                        editErrors.password.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      {editErrors.data_nascimento &&
                        editErrors.data_nascimento.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      {editErrors.password_confirm &&
                        editErrors.password_confirm.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      {editErrors.cpf &&
                        editErrors.cpf.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                    </ul>
                  </div>
                </IonCol>
                <IonCol
                  className={showEdicaoSenha ? "hide" : ""}
                  size="10"
                  offset="1"
                >
                  <TextField
                    required={true}
                    onchange={(value: string) => setNome(value)}
                    value={nome}
                    label="Nome"
                    type="text"
                    name="nome"
                    placeholder="Digite o nome"
                  />
                </IonCol>
                <IonCol
                  className={showEdicaoSenha ? "hide" : ""}
                  size="10"
                  offset="1"
                >
                  <TextField
                    required={true}
                    onchange={(value: string) => setEmail(value)}
                    value={email}
                    label="E-mail"
                    type="email"
                    name="email"
                    placeholder="Digite o email"
                  />
                </IonCol>
                <IonCol
                  className={!showEdicaoSenha ? "hide" : ""}
                  size="10"
                  offset="1"
                >
                  <TextField
                    onchange={(value: string) => setSenha(value)}
                    value={senha}
                    label="Senha"
                    type="password"
                    name="senha"
                    placeholder="Digite a senha"
                  />
                </IonCol>
                <IonCol
                  className={!showEdicaoSenha ? "hide" : ""}
                  style={{ display: `${showEdicaoSenha ? "block" : "none"}` }}
                  size="10"
                  offset="1"
                >
                  <TextField
                    required={senha !== "" && true}
                    onchange={(value: string) => setConfirmaSenha(value)}
                    value={confirmaSenha}
                    label="Confirmar senha"
                    type="password"
                    name="senha"
                    placeholder="Confirme a senha"
                  />
                </IonCol>
                <IonCol
                  className={showEdicaoSenha ? "hide" : ""}
                  size="5"
                  offset="1"
                >
                  <MaskedTextField
                    mask="999.999.999-99"
                    onchange={(value: string) => setCpf(value)}
                    value={cpf}
                    label="CPF"
                    type="text"
                    name="cpf"
                    placeholder="Digite o CPF"
                  />
                </IonCol>
                <IonCol
                  className={showEdicaoSenha ? "hide" : ""}
                  size="10"
                  sizeLg="5"
                  offset="1"
                  offsetLg="0"
                >
                  <MaskedTextField
                    mask="(99) 99999-9999"
                    onchange={(value: string) => setCelular(value)}
                    value={celular}
                    label="Celular"
                    type="text"
                    name="celular"
                    placeholder="Digite o celular"
                  />
                </IonCol>
                <IonCol
                  className={showEdicaoSenha ? "hide" : ""}
                  size="5"
                  offset="1"
                >
                  <MaskedTextField
                    mask="99/99/9999"
                    onchange={(value: string) => setDtNasc(value)}
                    value={dtNasc}
                    label="Data de nascimento"
                    type="text"
                    name="dt_nasc"
                    placeholder="Data de nascimento"
                  />
                </IonCol>
                {!showEdicaoSenha && dados.vinculo_facebook && (
                  <IonCol style={{ marginBottom: "10px" }} size="10" offset="1">
                    <CheckboxBasic
                      label="Desvincular Facebook"
                      checked={false}
                      value="1"
                      onchange={() =>
                        setDesvincularFacebook(!desvincularFacebook)
                      }
                    />
                  </IonCol>
                )}
                {!showEdicaoSenha && dados.vinculo_google && (
                  <IonCol style={{ marginBottom: "10px" }} size="10" offset="1">
                    <CheckboxBasic
                      label="Desvincular Google"
                      checked={false}
                      value="1"
                      onchange={() => setDesvincularGoogle(!desvincularGoogle)}
                    />
                  </IonCol>
                )}
                <IonCol size="10" offset="1">
                  <ButtonForm onclick={() => {}} text="Editar" />
                </IonCol>
              </IonRow>
            </form>
          </div>
        </IonModal>

        <Navbar />
        <div className="main-info flex flex-column justify-center align-center">
          <div className="perfil-image">
            <img src={DefaultUser} alt="" />
          </div>
          <h1
            style={{ margin: "0" }}
            className="primary-font text-aster-red font-big ion-text-center"
          >
            {user?.nome}
          </h1>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol size="10" offset="1">
              <div className="blockquote ion-text-center primary-regular">
                Dados pessoais
              </div>
            </IonCol>
            <IonCol size="5" offset="1">
              <div
                onClick={() => openModalEdicao()}
                className="blockquote red pointer ion-text-center primary-regular"
              >
                Editar dados
              </div>
            </IonCol>
            <IonCol size="5" offset="0">
              <div
                onClick={() => openModalEdicao(true)}
                className="blockquote gray pointer ion-text-center primary-regular"
              >
                Alterar senha
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="10" offset="1">
              <ul className="dados-lista">
                <li>
                  <div className="label primary-font text-gray">Nome</div>
                  <div className="dado primary-font text-aster-red">
                    {loading ? (
                      <IonSpinner name="crescent" color="danger" />
                    ) : (
                      dados.nome
                    )}
                  </div>
                </li>
                <li>
                  <div className="label primary-font text-gray">E-mail</div>
                  <div className="dado primary-font text-aster-red">
                    {loading ? (
                      <IonSpinner name="crescent" color="danger" />
                    ) : (
                      dados.email
                    )}
                  </div>
                </li>
                <li>
                  <div className="label primary-font text-gray">CPF</div>
                  <div className="dado primary-font text-aster-red">
                    {loading ? (
                      <IonSpinner name="crescent" color="danger" />
                    ) : (
                      dados.cpf
                    )}
                  </div>
                </li>
                <li>
                  <div className="label primary-font text-gray">Celular</div>
                  <div className="dado primary-font text-aster-red">
                    {loading ? (
                      <IonSpinner name="crescent" color="danger" />
                    ) : (
                      dados.celular
                    )}
                  </div>
                </li>
                <li>
                  <div className="label primary-font text-gray">
                    Data de nascimento
                  </div>
                  <div className="dado primary-font text-aster-red">
                    {loading ? (
                      <IonSpinner name="crescent" color="danger" />
                    ) : (
                      dados.data_nascimento
                    )}
                  </div>
                </li>
                <li>
                  <div className="label primary-font text-gray">
                    Conectado com
                  </div>
                  <div className="dado primary-font text-aster-red">
                    {loading ? (
                      <IonSpinner name="crescent" color="danger" />
                    ) : (
                      (dados.vinculo_facebook ? "Facebook" : "") +
                      " " +
                      (dados.vinculo_google ? "Google" : "")
                    )}
                  </div>
                </li>
              </ul>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="10" offset="1">
              {loading ? (
                <IonSpinner name="crescent" color="danger" />
              ) : (
                !dados.vinculo_facebook && (
                  <ButtonSSO opt="Facebook"></ButtonSSO>
                )
              )}
            </IonCol>
            <IonCol size="10" offset="1">
              {loading ? (
                <IonSpinner name="crescent" color="danger" />
              ) : (
                !dados.vinculo_google && <ButtonSSO opt="Google"></ButtonSSO>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonToast
          isOpen={showToastSuccess}
          onDidDismiss={() => setShowToastSuccess(false)}
          message="Edição feita com sucesso!"
          color="success"
          duration={1000}
          cssClass="text-white primary-font"
        />
      </IonContent>
    </IonPage>
  );
};

export default Perfil;
