import React, { useState, useContext } from "react";
import { SidebarData } from "./SidebarData";
import { IconContext } from "react-icons";
import { IonCol, IonGrid, IonRouterLink, IonRow } from "@ionic/react";

import * as AiIcons from "react-icons/ai";
import * as VscIcons from "react-icons/vsc";
import { BiExit } from "react-icons/bi";

import AuthContext from "./../contexts/auth";
import DefaultUser from "../assets/images/user-default.png";

import "../assets/css/components/navbar.css";
import { Redirect } from "react-router-dom";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";

const Navbar: React.FC = () => {
  const [sidebar, setSidebar] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const { signOut, user, recursos } = useContext(AuthContext);
  const [redirect, setRedirect] = useState<boolean>(false);

  const showSidebar = () => setSidebar(!sidebar);

  function handleSignOut() {
    signOut();
  }

  if (redirect) {
    return <Redirect to={{ pathname: "/unidades" }} />;
  }

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <IonRouterLink routerLink="#" className="menu-bars">
            <VscIcons.VscMenu onClick={showSidebar} />
          </IonRouterLink>
          <div className="logo-nav"></div>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <div className="header-nav flex align-center">
            <IonGrid>
              <IonRow>
                <IonCol size="3" className="flex align-center justify-end">
                  <div className="fotoNav">
                    <img src={DefaultUser} alt="" />
                  </div>
                </IonCol>
                <IonCol
                  size="9"
                  style={{ paddingTop: "15px" }}
                  className="flex align-start flex-column"
                >
                  <div
                    style={{
                      margin: "0",
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      paddingRight: "5px",
                    }}
                  >
                    <div>
                      <h1 className="primary-font font-medium text-white nomeNav">
                        {user?.nome}
                      </h1>
                      <IonRouterLink
                        className="text-white"
                        routerLink="/perfil"
                        routerDirection="none"
                      >
                        <span
                          style={{ margin: "0" }}
                          className="primary-font text-white font-extra-small"
                        >
                          Editar perfil e senha
                        </span>
                      </IonRouterLink>
                    </div>
                    <AiIcons.AiOutlineClose
                      onClick={showSidebar}
                      className="font-big pointer"
                    />
                  </div>
                  {!JSON.parse(
                    localStorage.getItem("@auth:subconta_unica") || "false"
                  ) ? (
                    <div>
                      <span
                        onClick={() => setOpenInfo(!openInfo)}
                        style={{
                          margin: "0",
                          marginTop: "10px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                        className="primary-font text-white font-extra-small pointer"
                      >
                        {
                          JSON.parse(
                            localStorage.getItem("@auth:subconta") || ""
                          ).conta
                        }
                        <button
                          onClick={() => setOpenInfo(!openInfo)}
                          className="button-action-info font-1-2 text-aster-red-dark flex justify-center align-center"
                        >
                          {openInfo ? (
                            <HiOutlineChevronUp />
                          ) : (
                            <HiOutlineChevronDown />
                          )}
                        </button>
                      </span>
                    </div>
                  ) : null}
                </IonCol>
                <IonCol size="9" offset="3">
                  <div
                    className={
                      "subconta-info primary-font text-white font-small" +
                      (openInfo ? " info-opened" : "")
                    }
                  >
                    <p>
                      Torre:{" "}
                      {
                        JSON.parse(localStorage.getItem("@auth:subconta") || "")
                          .torre
                      }
                    </p>
                    <p>
                      Unidade:{" "}
                      {
                        JSON.parse(localStorage.getItem("@auth:subconta") || "")
                          .unidade
                      }
                    </p>
                    <p>
                      Descricao:{" "}
                      {
                        JSON.parse(localStorage.getItem("@auth:subconta") || "")
                          .descricao
                      }
                    </p>
                    <button
                      onClick={() => setRedirect(true)}
                      className="btn-troca-subconta primary-regular"
                    >
                      TROCAR UNIDADE
                    </button>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <ul
            className="nav-menu-items primary-font text-white"
            onClick={showSidebar}
          >
            {/* <li className='navbar-toggle'>
                <IonRouterLink routerLink='#' className='menu-bars'>
                    <AiIcons.AiOutlineClose />
                </IonRouterLink>
                </li> */}
            {SidebarData.map((item, index) => {
              if (
                item.path === "/contatos" &&
                !recursos.includes("Controle de acesso")
              ) {
                return null;
              }

              if (item.path === "/paginas" && !recursos.includes("Bem-vindo")) {
                return null;
              }

              if (
                item.path === "/documentos" &&
                !recursos.includes("Documentos")
              ) {
                return null;
              }

              if (item.path === "/hospedes" && !recursos.includes("Hóspedes")) {
                return null;
              }

              return (
                <li key={index} className={item.cName}>
                  <IonRouterLink
                    className="text-white"
                    routerLink={item.path}
                    routerDirection="none"
                  >
                    {item.icon}&nbsp;
                    <span>
                      <strong>{item.title}</strong>
                    </span>
                  </IonRouterLink>
                </li>
              );
            })}
            <li className="nav-text text-white pointer" onClick={handleSignOut}>
              <BiExit />
              &nbsp;
              <span>
                <strong>Sair</strong>
              </span>
            </li>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
