import React from 'react';
import {IonFooter, IonToolbar, IonTitle} from '@ionic/react';

export const FooterExample: React.FC = () => (

    <IonFooter className="ion-no-border">
        <IonToolbar>
            <IonTitle>
                <a className="text-gray" href="https://www.aster.com.br/politica-de-privacidade/" rel="noopener noreferrer" target="_blank">
                    <span>Política de Privacidade</span>
                </a>
                <span>|</span>
                <a className="text-gray" href="https://www.aster.com.br/termos-de-uso/" rel="noopener noreferrer" target="_blank">
                    <span>Termos de Uso</span>
                </a>
            </IonTitle>
        </IonToolbar>
    </IonFooter>
);
