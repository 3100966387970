import React from 'react';

import '../assets/css/components/button-basic.css'

interface buttonBasicProps{
    text?: string,
    onclick: Function,
    disabled?: boolean,
    type?: "button" | "submit" | "reset" | undefined
}

const ButtonBasic: React.FC<buttonBasicProps> = (props) => {
    return(
        <>
            <button 
                disabled={props.disabled}
                type={props.type}
                onClick={() => props.onclick()} 
                className={"btn-basic font-1-1 primary-regular" + (props.disabled ? ' btn-basic-disabled' : '')}>
                    { props.children || props.text}
            </button>
        </>
    )
}

export default ButtonBasic