import React from "react";
import { Redirect, Route } from "react-router-dom";

import Login from "./../pages/Login";
import PrimeiroAcesso from "./../pages/PrimeiroAcesso";
import Cadastro from "../pages/Cadastro";
import EsqueciSenha from "./../pages/EsqueciSenha";
import RecuperarSenha from "./../pages/RecuperarSenha";
import { TermosUso } from "../pages/TermosUso";

const AuthRoutes: React.FC = () => {
  return (
    <>
      <Route exact path="/" component={Login} />
      <Route path="/primeiro-acesso" component={PrimeiroAcesso} exact={true} />
      <Route path="/esqueci-senha" component={EsqueciSenha} exact={true} />
      <Route
        path="/recuperar-senha/:t"
        component={RecuperarSenha}
        exact={true}
      />
      <Route path="/cadastro" component={Cadastro} exact={true} />
      <Route path="/termos-de-uso" component={TermosUso} exact={true} />
      <Route
        path="/boletos"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
      <Route
        path="/contatos"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
      <Route
        path="/documentos"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
      <Route
        path="/paginas"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
      <Route
        path="/pessoas"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
      <Route
        path="/abertura-de-chamados"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
      <Route
        path="/perfil"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
      <Route
        path="/recuperar-senha"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
      <Route
        path="/hospedes"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
      <Route
        path="/subcontas"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
      <Route
        path="/unidades"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
    </>
  );
};

export default AuthRoutes;
