import { IonSpinner } from "@ionic/react";
import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { GrFacebook } from "react-icons/gr";

import "../assets/css/components/button-sso.css";

const ButtonSSO: React.FC<{ opt: string }> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const renderIcon = () => {
    switch (props.opt) {
      case "Facebook":
        return <GrFacebook className="facebook-text" />;
      case "Google":
        return <FcGoogle />;
      default:
        break;
    }
  };

  const handleOnCLick = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    switch (props.opt) {
      case "Facebook":
        return (window.location.href =
          `${process.env.REACT_APP_API_URL}/auth/login/facebook`);
      case "Google":
        return (window.location.href =
          `${process.env.REACT_APP_API_URL}/auth/login/google`);
      default:
        break;
    }
  };

  return (
    <div style={{textAlign: 'center', minHeight: '58px'}}>
      {loading ? (
        <IonSpinner name="crescent" color="danger" />
      ) : (
        <button
          type="button"
          className={
            "btn-sso primary-font " + props.opt.toLocaleLowerCase() + "-border"
          }
          onClick={handleOnCLick}
        >
          <div className="icone-opt">{renderIcon()}</div>
          <div className={"text-button " + props.opt.toLocaleLowerCase()}>
            Conectar com {props.opt}
          </div>
        </button>
      )}
    </div>
  );
};

export default ButtonSSO;
