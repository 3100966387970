import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const contentTypes: any = {
    'application/pdf': '.pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'application/msword': '.doc',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
    'application/vnd.ms-excel': '.xls',
    'application/msexcel': '.xls',
    'application/excel': '.xls',
    'application/x-photoshop': '.psd',
    'image/jpeg': '.jpeg',
    'image/pjpeg': '.jpeg',
    'image/png': '.png',
    'image/tiff': '.tiff',
    'text/html': '.html',
    'text/plain': '.txt',
    'text/xml': '.xml',
    'application/json': '.json',
    'text/json': '.json'
}

interface Document{
    data:[{
        id: number;
        nome: string;
        tipo: string;
        url: string;
        data_referencia: string;
    }],
    last_page: number;
}

export async function getDocuments(page:number, params:string): Promise<Document>{
    const subconta = JSON.parse(localStorage.getItem('@auth:subconta') || "");
    var config:object = {
        method: 'get',
        url: `${API_URL}/documentos?page=${page}${params}&subconta=${subconta.id}`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        }
    };

    try{
        const resp = await axios(config);
        return {
            data: resp.data.data,
            last_page: resp.data.meta.last_page
        };
    }catch(error){ 
        throw new Error(error);
    }
}

export async function getDocExt(url: string){
    var config:object = {
        method: 'get',
        url: url,
        headers: { 
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        },
        responseType: 'blob'
    };

    try{
        const resp = await axios(config);
        const ext = contentTypes[resp.headers['content-type']]
        return ext;
    }catch(error){
        throw error.response.data.error;
    }
}

export async function downloadDoc(url:string, titulo:string): Promise<void>{
    var config:object = {
        method: 'get',
        url: url,
        headers: { 
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        },
        responseType: 'blob'
    };

    try{
        const resp = await axios(config);
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        const ext = contentTypes[resp.headers['content-type']]
        link.href = url;
        link.setAttribute('download', `${titulo}${ext}`); //or any other extension
        document.body.appendChild(link);
        link.click();
    }catch(error){
        throw error.response.data.error;
    }
}

export async function downloadMultiDocs(data:Array<{id:number,titulo:string, url:string}>): Promise<void>{

    try{
        for(let i = 0; i<data.length; i++){
            var config:object = {
                method: 'get',
                url: data[i].url,
                headers: { 
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
                },
                responseType: 'blob'
            };
            const resp = await axios(config);
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            const ext = contentTypes[resp.headers['content-type']]
            link.href = url;
            link.setAttribute('download', `${data[i].titulo}${ext}`); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
    }catch(error){ 
        throw error.response.data.error;
    }
}