import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import './../assets/css/components/modal.css';

interface ModalProps{
    class: string;
}

const Modal: React.FC<ModalProps> = (props) => {

    return(
        <div className={`modal-container ${props.class}`}>
            <IonGrid>
                <IonRow>
                    <IonCol size="10" sizeMd="4" offset="1" offsetMd="4">
                        <div className="modal-content">
                            {props.children}
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}

export default Modal