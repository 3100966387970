import React, { createContext, useState, useEffect, useCallback } from "react";
import * as auth from "./../services/auth";
import { getPerfil } from "./../services/helper";

interface AuthContextData {
  signed: boolean;
  user: User | null;
  loading: boolean;
  recursos: string[];
  pass: string;
  setPass(pass: string): void;
  signIn(email: string, password: string): Promise<void>;
  signInSSO(searchString: string, provider?: string): Promise<void>;
  signOut(): void;
  setExpired(): void;
  refreshToken(): Promise<void>;
  setRecursos(recursos: string[]): void;
  fetchRecursos(): void;
}

interface User {
  email: string;
  nome: string;
  cadastro_tipo: {
    id: number;
    tipo: string;
  };
  sub_conta: any;
  acesso_app_meu_bairro: number;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [pass, setPass] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [recursos, setStateRecursos] = useState<string[]>([]);

  useEffect(() => {
    if (isExpired) {
      signOut();
    }
    setIsExpired(false);
  }, [isExpired]);

  useEffect(() => {
    async function loadStoragedData() {
      const storagedUser = localStorage.getItem("@auth:user");
      const storagedToken = localStorage.getItem("@auth:token");
      const storagedExpiresIN = localStorage.getItem("@auth:expires_in");
      const recursos = localStorage.getItem("@auth:recursos");

      if (storagedUser && storagedToken && storagedExpiresIN) {
        setUser(JSON.parse(storagedUser));
        setRecursos(JSON.parse(String(recursos)) || []);
        setLoading(false);
      }
    }
    loadStoragedData();
  }, []);

  async function signIn(email: string, password: string) {
    localStorage.clear();
    setLoading(true);
    try {
      const response = await auth.signIn(email, password);
      localStorage.setItem("@auth:token", response.token);
      localStorage.setItem("@auth:user", JSON.stringify(response.user));
      try {
        await getPerfil(response.token);
        localStorage.setItem("@auth:expires_in", response.expires_in);
        setUser(response.user);
        setLoading(false);
      } catch (e) {
        throw new Error("primeiro_acesso");
      }
    } catch (e) {
      setLoading(false);
      if (e.message === "primeiro_acesso") {
        throw new Error(e.message);
      } else {
        throw new Error("E-mail ou senha incorretos!");
      }
    }
  }

  async function signInSSO(searchString: string, provider: string = "google") {
    setLoading(true);

    try {
      const response = await auth.signInSSO(searchString, provider);

      localStorage.clear();

      localStorage.setItem("@auth:token", response.token);
      localStorage.setItem("@auth:user", JSON.stringify(response.user));

      try {
        await getPerfil(response.token);
        localStorage.setItem("@auth:expires_in", response.expires_in);
        setUser(response.user);
        setLoading(false);
      } catch (e) {
        throw new Error("primeiro_acesso");
      }
    } catch (e) {
      setLoading(false);
      if (e.message === "primeiro_acesso") {
        throw new Error(e.message);
      } else {
        throw new Error("Nenhuma conta vinculada foi encontrada!");
      }
    }
  }

  const refreshToken = useCallback(async () => {
    try {
      const response = await auth.updateToken();
      localStorage.setItem("@auth:token", response.token);
      localStorage.setItem("@auth:expires_in", response.expires_in);
      setUser(response.user);
    } catch (e) {
      signOut();
    }
  }, []);

  const fetchRecursos = () => {
    auth.portalRecursos().then((response) => setRecursos(response.data));
  };

  function setRecursos(recursos: string[]) {
    setStateRecursos(recursos);
    localStorage.setItem("@auth:recursos", JSON.stringify(recursos));
  }

  function signOut() {
    setUser(null);
    localStorage.clear();
  }

  const setExpired = useCallback(() => {
    setIsExpired(true);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        recursos,
        loading,
        signIn,
        signInSSO,
        signOut,
        setExpired,
        setRecursos,
        refreshToken,
        pass,
        setPass,
        fetchRecursos,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
