import { 
    IonContent,
    IonPage,
} from '@ionic/react';
import React from 'react';
import './../assets/css/controle-acesso.css';


function ControleDeAcesso() {
    return (
        <IonPage className="common-pages">
            <IonContent fullscreen>

            </IonContent>
        </IonPage>
    )
}

export default ControleDeAcesso
