import React, { useState } from "react";
import {
  IonActionSheet,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { FaEllipsisV, FaIdBadge } from "react-icons/fa";
import "../assets/css/components/person-card.css";
import moment from "moment";

import checkPhoto from "./../assets/images/check_photo.png";

interface GuestCardProps {
  name: string;
  description?: string;
  id_guest: number;
  entrada: string;
  saida: string;
  hash: string;
  hasPhoto: boolean;
  onClickCopy: Function;
  edit: Function;
  delete: Function;
}

const GuestCard: React.FC<GuestCardProps> = (props) => {
  const [showActionSheet, setShowActionSheet] = useState(false);

  return (
    <IonCard className="person-card">
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol
              size="2"
              className="text-aster-red font-1-2 ion-text-center flex align-center justify-center"
            >
              {props.hasPhoto ? <img src={checkPhoto} alt="" /> : <FaIdBadge />}
            </IonCol>
            <IonCol size="7">
              <h5 className="text-black primary-font font-medium">
                {props.name}
              </h5>
              <p className="font-extra-small primary-regular text-aster-red">
                Documento: {props.description}
              </p>
              <p className="font-extra-small primary-regular text-aster-red">
                Entrada:{" "}
                {moment(props.entrada, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm"
                )}
              </p>
              <p className="font-extra-small primary-regular text-aster-red">
                Saída:{" "}
                {moment(props.saida, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm"
                )}
              </p>
            </IonCol>
            <IonCol
              size="3"
              className="flex flex-column justify-center align-center font-1-2"
            >
              <button
                onClick={() => setShowActionSheet(true)}
                className="button-action font-1-2 text-aster-red-dark flex justify-center align-center"
              >
                <FaEllipsisV />
              </button>
            </IonCol>
          </IonRow>
        </IonGrid>


        {
          props.hasPhoto ?
            <IonActionSheet
              isOpen={showActionSheet}
              onDidDismiss={() => setShowActionSheet(false)}
              buttons={[
                {
                  text: "Deletar",
                  role: "destructive",
                  handler: () => {
                    props.delete(props.id_guest, props.name);
                  },
                },
                {
                  text: "Editar",
                  handler: () => {
                    props.edit(props.id_guest);
                  },
                },
                {
                  text: "Cancelar",
                  icon: "close",
                  role: "cancel",
                  handler: () => {
                    console.log("Cancel clicked");
                  },
                },
              ]}
            ></IonActionSheet> : 
            <IonActionSheet
              isOpen={showActionSheet}
              onDidDismiss={() => setShowActionSheet(false)}
              buttons={[
                {
                  text: "Deletar",
                  role: "destructive",
                  handler: () => {
                    props.delete(props.id_guest, props.name);
                  },
                },
                {
                  text: "Editar",
                  handler: () => {
                    props.edit(props.id_guest);
                  },
                },
                {
                  text: "Copiar link",
                  handler: () => {
                    props.onClickCopy(props.hash);
                  },
                },
                {
                  text: "Cancelar",
                  icon: "close",
                  role: "cancel",
                  handler: () => {
                    console.log("Cancel clicked");
                  },
                },
              ]}
            ></IonActionSheet>
        }


      </IonCardContent>
    </IonCard>
  );
};

export default GuestCard;
