import { 
    IonCol, 
    IonContent, 
    IonGrid, 
    IonPage, 
    IonRow,
    IonCard,
    IonCardContent,
    IonRouterLink,
    IonHeader,
    IonButtons,
    IonToolbar,
    IonBackButton
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {FaBuilding} from 'react-icons/fa'

import ButtonBasic from '../components/ButtonBasic'

import Logo from '../assets/images/logo_ASTER.png';

const PrimeiroAcesso: React.FC = () => {

    const [unidade, setUnidade] = useState<string>("");
    const [conta, setConta] = useState<string>("");

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('@auth:user') || "")
        if(user.sub_conta){
            setUnidade(user.sub_conta.unidade)
            setConta(user.sub_conta.conta.nome)
        }
        
    }, [unidade])

    return (
        <IonPage>
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text="Voltar" defaultHref="/" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
        <IonContent fullscreen>
            <IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="8" sizeMd="4" sizeLg="2">
                        <div className="login-screen-logo">
                            <img src={Logo} alt="logo"/>
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="10" sizeMd="4" sizeLg="2" offset="1" offsetMd="4" offsetLg="5">
                        <h4 className="ion-text-center primary-font"><strong>Primeiro acesso</strong></h4>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="10" sizeMd="4" offset="1" offsetMd="4">
                        <IonCard>
                            <IonCardContent className="text-aster-red ion-justify-content-center ion-align-items-center ion-text-center">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol>
                                            <FaBuilding/>
                                        </IonCol>
                                    </IonRow>
                                    
                                    <IonRow>
                                        <IonCol>
                                            <h3 className="primary-font text-black">{conta}</h3>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <p className="primary-font text-aster-red font-extra-small">{ unidade }</p>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="10" sizeMd="4" offset="1" offsetMd="4">
                        <IonRouterLink routerLink="/cadastro"><ButtonBasic onclick={() => {}} text="COMPLETAR CADASTRO" /></IonRouterLink>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
        </IonPage>
    );
};

export default PrimeiroAcesso;
