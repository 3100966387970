import { IonCard, IonCardContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import React from 'react';
import { FaDownload } from 'react-icons/fa';

import '../assets/css/components/card-doc.css';

interface CardDocProps{
    id:number,
    tipo: string,
    titulo: string,
    url: string;
    dataRef: string;
    viewDoc: Function;
    onchange: Function;
    clickDownload: Function
}

const CardDoc: React.FC<CardDocProps> = (props) => {

    return(
        <IonCard className="card-doc">
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size="2" className="flex align-center justify-center">
                            <label className="checkbox-container">
                                <input onChange={(e:any) => props.onchange(props.id,props.titulo,props.url, e.target.checked)} type="checkbox" defaultChecked={false} />
                                <span className="checkmark"></span>
                            </label>
                        </IonCol>
                        <IonCol size="8" className="primary-font">
                            <h5 onClick={() => props.viewDoc(props.url, props.titulo)} className="pointer text-black font-1-1">{props.titulo}</h5>
                            <span className="primary-font font-small text-black" style={{margin:'0', paddingTop:'5px', paddingBottom:'5px'}}>{props.dataRef}</span><br/>
                            {
                                props.tipo &&
                                <div className={"primary-font label-tipo font-extra-small text-white bold "+ 
                                    (props.tipo).replace(/ /g,'-').replace(/\)/g,'').replace(/\(/g,'').toLocaleLowerCase()}>
                                    <strong>{(props.tipo).toLocaleUpperCase()}</strong>
                                </div>
                            }
                        </IonCol>
                        <IonCol size="2" className="flex justify-center align-center font-1-2">
                            <button onClick={() => props.clickDownload(props.url, props.titulo)} className="button-action font-1-2 text-aster-red-dark flex justify-center align-center"><FaDownload /></button>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

export default CardDoc