import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  IonModal,
  IonSpinner,
} from "@ionic/react";
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  FormEvent,
} from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaRegSadTear, FaLock, FaIdBadge } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
import moment from "moment";

import Navbar from "../components/Navbar";
import Modal from "../components/Modal";
import TextField from "../components/TextField";
import MaskedTextField from "../components/MaskedTextField";
import ButtonForm from "../components/ButtonForm";
import GuestCard from "../components/GuestCard";

import AuthContext from "./../contexts/auth";

import "../assets/css/pessoas.css";
import { FooterExample } from "../components/Footer";
import {
  deleteGuest,
  editGuest,
  getGuests,
  registerGuest,
} from "../services/guests";
import CheckboxBasic from "../components/CheckboxBasic";

interface Guest {
  id: number;
  nome: string;
  email: string;
  foto?: string;
  cpf?: string;
  documento?: string;
  celular: string;
  data_nascimento: string;
  data_entrada: string;
  data_saida: string;
  hash: string;
}

interface Tipo {
  id: number;
  tipo: string;
}
interface Errors {
  nome: Array<string>;
  email: Array<string>;
  cpf: Array<string>;
  documento: Array<string>;
  celular: Array<string>;
  data_nascimento: Array<string>;
  data_entrada: Array<string>;
  data_saida: Array<string>;
}

const Hospedes: React.FC = () => {
  moment.locale("pt-br");

  const { setExpired, refreshToken, user } = useContext(AuthContext);

  const [guests, setGuests] = useState<Array<Guest>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [loadingCadastro, setLoadingCadastro] = useState<boolean>(false);
  const [registerErrors, setRegisterErrors] = useState({} as Errors);
  const [edit, setEdit] = useState<boolean>(false);

  // States of Modal
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [msgModalSuccess, setMsgModalSuccess] = useState<string>("");
  const [hashLink, setHashLink] = useState<string>("");
  const [nomePessoa, setNomePessoa] = useState<string>("");
  const [idHospede, setIdHospede] = useState<number>(0);

  // States of Register Modal
  const [showModalCadastro, setShowModalCadastro] = useState<boolean>(false);
  const [nome, setNome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [checkEmail, setCheckEmail] = useState<boolean>(true);
  const [cpf, setCpf] = useState<string>("");
  const [documento, setDocumento] = useState<string>("");
  const [celular, setCelular] = useState<string>("");
  const [dtNasc, setDtNasc] = useState<string>(moment().format("DD/MM/YYYY"));
  const [horarioEntrada, setHorarioEntrada] = useState<string>("00:00");
  const [horarioSaida, setHorarioSaida] = useState<string>("23:59");
  const [dtEntrada, setDtEntrada] = useState<string>(moment().format());
  const [dtSaida, setDtSaida] = useState<string>(
    moment().add(1, "day").format()
  );

  // Toasts
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showToastSuccess, setShowToastSuccess] = useState<boolean>(false);
  const [showToastCopy, setShowToastCopy] = useState<boolean>(false);
  const [showToastError, setShowToastError] = useState<boolean>(false);

  const loadGuests = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await getGuests();
      setGuests(resp);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, []);

  // Monitor the page state, every time page changes this function get the current page from api
  useEffect(() => {
    const expire_date = new Date(
      localStorage.getItem("@auth:expires_in") || ""
    );
    const now = new Date(moment().format());
    if (now.getTime() > expire_date.getTime()) {
      setExpired();
    } else {
      loadGuests();
    }
  }, [loadGuests, refreshToken, setExpired]);

  async function openModalCadastro() {
    console.log(documento)
    setShowModalCadastro(true);
    setDtEntrada(moment().format("DD/MM/YYYY"));
    setDtSaida(moment().add(1, "day").format("DD/MM/YYYY"));
  }

  async function getGuestById(id: number) {
    setEdit(true);
    const guest = guests.filter((item: any) => item.id === id);

    let dataEntrada = guest[0].data_entrada.split(" ");
    let dataSaida = guest[0].data_saida.split(" ");
    let hrEntrada = dataEntrada[1].split(":");
    let hrSaida = dataSaida[1].split(":");

    setIdHospede(guest[0].id);
    setNome(guest[0].nome || "");
    setEmail(guest[0].email || "");
    setCpf(guest[0].cpf || "");
    setDocumento(guest[0].documento || "");
    setDtEntrada(
      moment(guest[0].data_entrada, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") || ""
    );
    setDtSaida(
      moment(guest[0].data_saida, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") || ""
    );
    setHorarioEntrada(`${hrEntrada[0]}:${hrEntrada[1]}`);
    setHorarioSaida(`${hrSaida[0]}:${hrSaida[1]}`);
    setCelular(guest[0].celular || "");
    setDtNasc(
      moment(guest[0].data_nascimento, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") || ""
    );

    openModalCadastro();
  }

  function copyHash(hash: string) {
    navigator.clipboard.writeText(hash);
    setShowToastCopy(true);
  }

  function closeModalCadastro() {
    setRegisterErrors({} as Errors);
    setShowModalCadastro(false);
    setEdit(false);
    clearFields();
  }

  // Call the function to delete the guest
  async function handleDeleteGuest() {
    try {
      await deleteGuest(idHospede);
      setToastMessage("Hóspede deletado com sucesso");
      setShowToastSuccess(true);
    } catch (e) {
      setShowToastError(true);
    }
    setShowModalDelete(false);
    setGuests([]);
    loadGuests();
  }

  function openDeleteModal(id: number, name: string) {
    setNomePessoa(name);
    setIdHospede(id);
    setShowModalDelete(true);
  }

  // function validarCPF(cpf: string) {
  //   cpf = cpf.replace(/[^\d]+/g, "");
  //   if (cpf === "") return false;
  //   // Elimina CPFs invalidos conhecidos
  //   if (
  //     cpf.length !== 11 ||
  //     cpf === "00000000000" ||
  //     cpf === "11111111111" ||
  //     cpf === "22222222222" ||
  //     cpf === "33333333333" ||
  //     cpf === "44444444444" ||
  //     cpf === "55555555555" ||
  //     cpf === "66666666666" ||
  //     cpf === "77777777777" ||
  //     cpf === "88888888888" ||
  //     cpf === "99999999999"
  //   )
  //     return false;
  //   // Valida 1o digito
  //   let add = 0;
  //   for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  //   let rev = 11 - (add % 11);
  //   if (rev === 10 || rev === 11) rev = 0;
  //   if (rev !== parseInt(cpf.charAt(9))) return false;
  //   // Valida 2o digito
  //   add = 0;
  //   for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  //   rev = 11 - (add % 11);
  //   if (rev === 10 || rev === 11) rev = 0;
  //   if (rev !== parseInt(cpf.charAt(10))) return false;
  //   return true;
  // }

  // function validateFields(data: any) {
  //   let errors: any = {};

  //   if (!data.documento && !data.cpf) {
  //     errors.cpf = [];
  //     errors.documento = [];
  //     errors.cpf.push("CPF é obrigatório quando o 'documento' não está presente.");
  //     errors.documento.push("O campo 'documento' é obrigatório quando o 'CPF' não está presente.");
  //   }

  //   if (data.cpf && !data.documento) {
  //     if (!validarCPF(data.cpf)) {
  //       errors.cpf = [];
  //       errors.cpf.push("CPF inválido");
  //     }
  //   }

  //   if (data.data_nascimento === "") {
  //     errors.data_nascimento = [];
  //     errors.data_nascimento.push("Data de nascimento é obrigatória!");
  //   } else {
  //     if (!moment(data.data_nascimento, "YYYY-MM-DD").isValid()) {
  //       errors.data_nascimento = [];
  //       errors.data_nascimento.push("Data de nascimento inválida");
  //     }
  //   }

  //   if (data.data_entrada === "") {
  //     errors.data_entrada = [];
  //     errors.data_entrada.push("Data de entrada é obrigatória!");
  //   } else {
  //     if (!moment(data.data_entrada, "YYYY-MM-DD HH:mm:ss").isValid()) {
  //       errors.data_entrada = [];
  //       errors.data_entrada.push("Data de entrada inválida");
  //     }
  //   }

  //   if (data.data_saida === "") {
  //     errors.data_saida = [];
  //     errors.data_saida.push("Data de saída é obrigatória!");
  //   } else {
  //     if (!moment(data.data_saida, "YYYY-MM-DD HH:mm:ss").isValid()) {
  //       errors.data_saida = [];
  //       errors.data_saida.push("Data de saída inválida");
  //     }
  //     if (data.data_entrada >= data.data_saida) {
  //       errors.data_saida = [];
  //       errors.data_saida.push(
  //         "Data de saída deve ser maior que data de entrada!"
  //       );
  //     }
  //   }

  //   return errors;
  // }

  async function sendEditGuest(e: FormEvent) {
    e.preventDefault();

    let subConta = null;

    if (localStorage.getItem("@auth:subconta")) {
      subConta = JSON.parse(String(localStorage.getItem("@auth:subconta")));
    }

    let data_entrada = moment(`${dtEntrada} ${horarioEntrada}`, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss");
    let data_saida = moment(`${dtSaida} ${horarioSaida}`, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss");

    const data = {
      id: subConta.id,
      nome,
      email,
      enviar_email: checkEmail ? 1 : 0,
      cpf: cpf.replace(/[-.]/g, ""),
      documento: documento,
      celular,
      data_nascimento: moment(dtNasc, "DD-MM-YYYY").format("YYYY-MM-DD"),
      data_entrada,
      data_saida,
    };

    setLoadingCadastro(true);
    try {
      const resp: any = await editGuest(data, idHospede);
      setHashLink(resp.hash);
      setLoadingCadastro(false);
      clearFields();
      setShowModalCadastro(false);
      setEdit(false);
      setGuests([]);
      setToastMessage("Hóspede editado com sucesso");
      setShowToastSuccess(true);
      loadGuests();
    } catch (error) {
      setRegisterErrors(error);
      document.querySelector(".content-modal-cadastro")?.scrollTo(0, 0);
      setLoadingCadastro(false);
    }
  }

  async function sendFormCadastro(e: FormEvent) {
    let subConta = null;

    if (localStorage.getItem("@auth:subconta")) {
      subConta = JSON.parse(String(localStorage.getItem("@auth:subconta")));
    }

    e.preventDefault();

    let data_entrada = moment(`${dtEntrada} ${horarioEntrada}`, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss");
    let data_saida = moment(`${dtSaida} ${horarioSaida}`, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss");

    const data = {
      id: subConta.id,
      nome,
      email,
      enviar_email: checkEmail ? 1 : 0,
      cpf: cpf.replace(/[-.]/g, ""),
      documento: documento,
      celular,
      data_nascimento: moment(dtNasc, "DD-MM-YYYY").format("YYYY-MM-DD"),
      data_entrada,
      data_saida,
    };

    setLoadingCadastro(true);
    try {
      const resp: any = await registerGuest(data);
      setHashLink(resp.hash);
      setLoadingCadastro(false);
      clearFields();
      setShowModalCadastro(false);
      setGuests([]);
      setToastMessage("Hóspede cadastrado com sucesso");
      setShowToastSuccess(true);
      setMsgModalSuccess(
        checkEmail
          ? "Hóspede cadastrado com sucesso, foi enviado um link para ele tirar a foto no email cadastrado."
          : "Hóspede cadastrado com sucesso, envie o link para o hóspede tirar a foto"
      );
      setShowModalSuccess(true);
      loadGuests();
    } catch (error) {
      setRegisterErrors(error);
      document.querySelector(".content-modal-cadastro")?.scrollTo(0, 0);
      setLoadingCadastro(false);
    }
  }

  function clearFields() {
    setNome("");
    setEmail("");
    setCpf("");
    setDocumento("");
    setCelular("");
    setDtNasc(moment().format());
    setHorarioEntrada("00:00");
    setHorarioSaida("23:59");
    setDtEntrada(moment().format());
    setDtSaida(moment().add(1, "day").format());
  }

  return (
    <IonPage className="common-pages">
      <IonContent fullscreen scrollEvents={true}>
        {/* Delete Modal */}
        <Modal class={showModalDelete ? "flex" : "hide"}>
          <div className="flex flex-column align-center ion-text-center">
            <p className="primary-font text-gray">
              Tem certeza que deseja excluir o cadastro de
            </p>
            <h3 className="primary-regular text-aster-red">"{nomePessoa}"</h3>
            <button
              onClick={() => setShowModalDelete(false)}
              className="btn-modal cancelar primary-regular font-medium ion-text-center"
            >
              Cancelar
            </button>
            <button
              onClick={() => handleDeleteGuest()}
              className="btn-modal action primary-regular font-medium ion-text-center"
            >
              Excluir
            </button>
          </div>
        </Modal>

        {/* Copy Link Modal */}
        <Modal class={showModalSuccess ? "flex" : "hide"}>
          <div className="flex flex-column align-center ion-text-center">
            <p className="primary-font text-gray">{msgModalSuccess} <span className="color-warning">Se preferir, copie e cole o link para compartilhar</span></p>
            <h3
              className="primary-regular text-aster-red"
              style={{ wordBreak: "break-word" }}
            >
              {hashLink}
            </h3>
            <button
              onClick={() => {
                navigator.clipboard.writeText(hashLink);
                setShowToastCopy(true);
              }}
              className="btn-modal cancelar primary-regular font-medium ion-text-center"
            >
              COPIAR
            </button>
            <button
              onClick={() => setShowModalSuccess(false)}
              className="btn-modal action primary-regular font-medium ion-text-center"
            >
              FECHAR
            </button>
          </div>
        </Modal>

        {/* Register Modal */}
        <IonModal
          isOpen={showModalCadastro}
          cssClass="text-black"
          onDidDismiss={closeModalCadastro}
        >
          {loadingCadastro ? (
            <div className="loading-login">
              <IonSpinner name="crescent" color="danger" />
            </div>
          ) : (
            ""
          )}
          <div className="header-modal-cadastro flex flex-row justify-space-between">
            <h1 className="primary-font text-white font-big">
              {edit ? "Edição de hóspede" : "Cadastro de hóspede"}
            </h1>
            <VscChromeClose
              style={{ marginTop: "10px" }}
              className="text-white font-big"
              onClick={() => setShowModalCadastro(false)}
            />
          </div>
          <div className="content-modal-cadastro">
            <form
              onSubmit={(e: any) =>
                edit ? sendEditGuest(e) : sendFormCadastro(e)
              }
              action=""
              method="post"
            >
              <IonRow>
                <IonCol size="10" offset="1">
                  <TextField
                    onchange={(value: string) => setNome(value)}
                    value={nome}
                    label="Nome *"
                    type="text"
                    name="nome"
                    placeholder="Digite o nome"
                  />
                  <div className="errors primary-font text-aster-red">
                    <ul>
                      {
                        registerErrors?.nome &&
                        registerErrors.nome.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })
                      }
                    </ul>
                  </div>
                </IonCol>


                <IonCol size="5" offset="1">
                  <MaskedTextField
                    mask="999.999.999-99"
                    onchange={(value: string) => setCpf(value)}
                    value={cpf}
                    label="CPF *"
                    type="text"
                    name="cpf"
                    placeholder="Digite o CPF"
                  />
                </IonCol>

                <IonCol size="5">
                  <TextField
                    onchange={(value: string) => setDocumento(value)}
                    value={documento}
                    label="Documento *"
                    type="text"
                    name="documento"
                    placeholder="Digite o documento"
                  />
                </IonCol>

                <IonCol size="10" style={{ marginTop: "-18px", marginLeft: '48px' }}>
                  {
                    <div className="errors primary-font text-aster-red">
                      <ul>
                        {
                          registerErrors?.cpf &&
                          registerErrors.cpf.map((item, index) => {
                            return <li key={index}>{item}</li>;
                          })
                        }
                      </ul>
                    </div>
                  }
                </IonCol>

                <IonCol size="10" offset="1">
                  {/* <div className="text-field-container">
                    <label className="primary-font text-aster-red font-small">
                      Data de nascimento *
                    </label>
                    <div className="datepicker primary-font font-medium">
                      <IonDatetime
                        className="primary-font"
                        monthShortNames="Jan, Fev, Mar, Abr, Mai, Jun, Jul, Ago, Set, Out, Nov, Dez"
                        displayFormat="DD/MM/YYYY"
                        placeholder="Selecione a data"
                        value={dtNasc}
                        doneText="Feito"
                        cancelText="Cancela"
                        onIonChange={(e) => {
                          // setDtNasc(e.detail.value!)
                          setDtNasc(e.detail.value!);
                        }}
                      ></IonDatetime>
                    </div>
                  </div> */}
                  <MaskedTextField
                    mask="99/99/9999"
                    onchange={(value: string) => setDtNasc(value)}
                    value={dtNasc}
                    label="Data de nascimento *"
                    type="text"
                    name="dt_nasc"
                    placeholder="Data de nascimento"
                  />
                  <div className="errors primary-font text-aster-red">
                    <ul>
                      {
                        registerErrors?.data_nascimento &&
                        registerErrors.data_nascimento.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })
                      }
                    </ul>
                  </div>
                </IonCol>

                <IonCol size="10" offset="1">
                  <MaskedTextField
                    mask="(99) 99999-9999"
                    onchange={(value: string) => setCelular(value)}
                    value={celular}
                    label="Celular"
                    type="text"
                    name="celular"
                    placeholder="Digite o celular"
                  />
                  <div className="errors primary-font text-aster-red">
                    <ul>
                      {
                        registerErrors?.celular &&
                        registerErrors.celular.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })
                      }
                    </ul>
                  </div>
                </IonCol>

                <IonCol size="5" offset="1">
                  {/* <div className="text-field-container">
                    <label className="primary-font text-aster-red font-small">
                      Data de entrada *
                    </label>
                    <div className="datepicker primary-font font-medium">
                      <IonDatetime
                        className="primary-font"
                        monthShortNames="Jan, Fev, Mar, Abr, Mai, Jun, Jul, Ago, Set, Out, Nov, Dez"
                        displayFormat="DD/MM/YYYY"
                        placeholder="Selecione a data"
                        value={dtEntrada}
                        doneText="Feito"
                        cancelText="Cancela"
                        onIonChange={(e) => {
                          // setDtNasc(e.detail.value!)
                          setDtEntrada(e.detail.value!);
                        }}
                      ></IonDatetime>
                    </div>
                  </div> */}
                  <MaskedTextField
                    mask="99/99/9999"
                    onchange={(value: string) => setDtEntrada(value)}
                    value={dtEntrada}
                    label="Data de entrada *"
                    type="text"
                    name="data_entrada"
                    placeholder="Digite a data"
                  />
                  <div className="errors primary-font text-aster-red">
                    <ul>
                      {registerErrors?.data_entrada &&
                        registerErrors.data_entrada.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                    </ul>
                  </div>
                </IonCol>

                <IonCol size="5">
                  <MaskedTextField
                    mask="99:99"
                    onchange={(value: string) => setHorarioEntrada(value)}
                    value={horarioEntrada}
                    label="Horário de entrada *"
                    type="text"
                    name="horario_entrada"
                    placeholder="Digite o horario"
                  />
                </IonCol>

                <IonCol size="5" offset="1">
                  {/* <div className="text-field-container">
                    <label className="primary-font text-aster-red font-small">
                      Data de saída *
                    </label>
                    <div className="datepicker primary-font font-medium">
                      <IonDatetime
                        className="primary-font"
                        monthShortNames="Jan, Fev, Mar, Abr, Mai, Jun, Jul, Ago, Set, Out, Nov, Dez"
                        displayFormat="DD/MM/YYYY"
                        placeholder="Selecione a data"
                        value={dtSaida}
                        doneText="Feito"
                        cancelText="Cancela"
                        onIonChange={(e) => {
                          // setDtNasc(e.detail.value!)
                          setDtSaida(e.detail.value!);
                        }}
                      ></IonDatetime>
                    </div>
                  </div> */}
                  <MaskedTextField
                    mask="99/99/9999"
                    onchange={(value: string) => setDtSaida(value)}
                    value={dtSaida}
                    label="Data de saída"
                    type="text"
                    name="data_saida"
                    placeholder="Digite a data"
                  />
                  <div className="errors primary-font text-aster-red">
                    <ul>
                      {registerErrors?.data_saida &&
                        registerErrors.data_saida.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                    </ul>
                  </div>
                </IonCol>

                <IonCol size="5">
                  <MaskedTextField
                    mask="99:99"
                    onchange={(value: string) => setHorarioSaida(value)}
                    value={horarioSaida}
                    label="Horário de saída *"
                    type="text"
                    name="horario_saida"
                    placeholder="Digite o horario"
                  />
                </IonCol>

                {!edit && (
                  <IonCol size="10" offset="1" style={{ marginBottom: "15px" }}>
                    <CheckboxBasic
                      onchange={() => setCheckEmail(!checkEmail)}
                      checked={checkEmail}
                      name="chk-regulamento"
                      value="1"
                      label="Enviar link por email para o hóspede tirar a foto"
                    />
                  </IonCol>
                )}
                {checkEmail && (<IonCol size="10" offset="1">
                  <TextField
                    onchange={(value: string) => setEmail(value)}
                    value={email}
                    label="E-mail *"
                    type="email"
                    name="email"
                    placeholder="Digite o e-mail do hóspede"
                  />
                  <div className="errors primary-font text-aster-red">
                    <ul>
                      {
                        registerErrors?.email &&
                        registerErrors.email.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })
                      }
                    </ul>
                  </div>
                </IonCol>)}


                <IonCol size="10" offset="1">
                  <ButtonForm
                    onclick={() => { }}
                    text={edit ? "EDITAR" : "Cadastrar"}
                  />
                </IonCol>
              </IonRow>
            </form>
          </div>
        </IonModal>

        {/* Navbar */}
        <Navbar />

        <div className="page-title-container">
          <div className="page-title justify-space-between">
            {/* <span className="primary-font font-medium text-aster-red bold">
              Hóspedes
            </span> */}
            <div className="flex justify-left">
              <span className="font-big text-aster-red bold">
                <FaIdBadge />
              </span>
              &nbsp;
              <span className="title-text primary-font font-1-2 text-aster-red bold">
                Hóspedes
              </span>
            </div>
            {(user?.cadastro_tipo === null ||
              user?.cadastro_tipo?.id === 5) && (
                <button
                  onClick={openModalCadastro}
                  className="button-action primary-font font-extra-small bold text-black"
                >
                  CADASTRAR HÓSPEDE
                </button>
              )}
          </div>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="10" sizeXl="8" offsetMd="1" offsetXl="2">
              <ul className="container-person-card">
                {guests.map((guest, index) => {
                  return (
                    <li key={index}>
                      <GuestCard
                        name={guest.nome}
                        hasPhoto={guest.foto !== null}
                        description={guest.cpf || guest.documento}
                        entrada={guest.data_entrada}
                        saida={guest.data_saida}
                        id_guest={guest.id}
                        delete={openDeleteModal}
                        edit={getGuestById}
                        hash={guest.hash}
                        onClickCopy={copyHash}
                      />
                    </li>
                  );
                })}
              </ul>
              {loading && (
                <h5 className="primary-font text-aster-red font-1-3 ion-text-center">
                  <AiOutlineLoading3Quarters className="spin" />
                </h5>
              )}
              {error && user?.cadastro_tipo?.id === 5 && (
                <>
                  <h5 className="primary-font text-aster-red font-1-4 ion-text-center">
                    <FaRegSadTear />
                  </h5>
                  <h5 className="primary-font text-aster-red font-medium ion-text-center">
                    Oops! Houve um erro no carregamento.
                  </h5>
                </>
              )}
              {error && user?.cadastro_tipo?.id !== 5 && (
                <>
                  <h5 className="primary-font text-aster-red font-1-4 ion-text-center">
                    <FaLock />
                  </h5>
                  <h5 className="primary-font text-aster-red font-medium ion-text-center">
                    Você não possui permissão para visualizar esse conteúdo.
                  </h5>
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* Toasts */}
        <IonToast
          isOpen={showToastCopy}
          onDidDismiss={() => setShowToastCopy(false)}
          message="Link copiado!"
          color="success"
          duration={1000}
          cssClass="text-white primary-font"
        />
        <IonToast
          isOpen={showToastSuccess}
          onDidDismiss={() => setShowToastSuccess(false)}
          message={toastMessage}
          color="success"
          duration={1000}
          cssClass="text-white primary-font"
        />
        <IonToast
          isOpen={showToastError}
          onDidDismiss={() => setShowToastError(false)}
          message="Não foi possível completar a operação!"
          color="danger"
          duration={1000}
          cssClass="text-white primary-font"
        />
      </IonContent >
      <FooterExample />
    </IonPage >
  );
};

export default Hospedes;
