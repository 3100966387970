import React from "react";
import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from "@ionic/react";
import { FaUser, FaClock, FaTimes, FaPen, FaIdBadge } from "react-icons/fa";
import "../assets/css/components/person-card.css";

interface PersonCardProps {
  name: string;
  type: string;
  type_id: number;
  id_person: number;
  edit: Function;
  delete: Function;
}

const PersonCard: React.FC<PersonCardProps> = (props) => {
  function renderIcon() {
    switch (props.type_id) {
      case 1:
        return <FaIdBadge />;
      case 5:
        return <FaUser />;
      case 3:
        return <FaClock />;
      default:
        break;
    }
  }

  return (
    <IonCard className="person-card">
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol
              size="2"
              className="text-aster-red font-1-2 ion-text-center flex align-center justify-center"
            >
              {renderIcon()}
            </IonCol>
            <IonCol size="7">
              <h5 className="text-black primary-font font-medium">
                {props.name}
              </h5>
              {props.type ? (
                <p className="font-extra-small primary-regular text-aster-red">
                  {props.type.replace("-", " ").toLocaleUpperCase()}
                </p>
              ) : (
                ""
              )}
            </IonCol>
            <IonCol
              size="3"
              className="flex justify-center align-center font-1-2"
            >
              <button
                onClick={() => props.edit(props.id_person)}
                className="button-action font-1-2 text-aster-red-dark flex justify-center align-center"
              >
                <FaPen />
              </button>
              <button
                onClick={() => props.delete(props.id_person, props.name)}
                className="button-action font-1-2 text-aster-red-dark flex justify-center align-center"
              >
                <FaTimes />
              </button>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default PersonCard;
