import axios from 'axios'
const API_URL = process.env.REACT_APP_API_VISITANTES_URL

interface Guest{
    id: number;
    nome: string;
    email: string;
    cpf: string;
    rg: string;
    guest: string;
    documento: string;
    celular: string;
    data_nascimento: string;
    data_entrada: string;
    data_saida: string;
    hash: string;
}

export async function getGuests():Promise<Guest[]>{
    const subconta = JSON.parse(localStorage.getItem('@auth:subconta') || "");
    var config:object = {
        method: 'get',
        url: `${API_URL}/hospedes/${subconta.id}`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        }
    };

    try{
        const req = await axios(config);
        return req.data.data;
    }catch(error){ 
        throw new Error(error);
    }
}

export async function registerGuest(data:object):Promise<any>{
    var config:object = {
        method: 'post',
        url: `${API_URL}/hospedes`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        },
        data: data
    };

    try{
        const req = await axios(config);
        return req.data.data;
    }catch(error){ 
        // console.log(error.response)
        throw error.response.data.errors;
    }
}

export async function editGuest(data:object, id:number):Promise<{message:string}>{
    var config:object = {
        method: 'put',
        url: `${API_URL}/hospedes/update/${id}`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        },
        data: data
    };

    try{
        await axios(config);
        return{
            message: "OK"
        };
    }catch(error){ 
        // console.log(error.response)
        throw error.response.data.errors;
    }
}

export async function deleteGuest(id:number):Promise<{message:string}>{
    var config:object = {
        method: 'delete',
        url: `${API_URL}/hospedes/${id}`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        },
    };

    try{
        await axios(config);
        return{
            message: "OK"
        };
    }catch(error){ 
        // console.log(error.response)
        throw error.response.data.errors;
    }
}
