import { IonCard, IonCardContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';

import '../assets/css/components/card-doc.css';

interface CardPaginaProps{
    id: number,
    titulo: string
}

const CardPagina: React.FC<CardPaginaProps> = (props) => {
    const history = useHistory();
    const { id } = props;
    return(
        <IonCard className="card-doc">
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol className="primary-font" onClick={() => history.push(`paginas/${id}`)}>
                            <h5 className="pointer text-black font-1-2">{props.titulo}</h5>
                            <button className="button-action font-1-1 text-aster-red-dark flex justify-center align-center">Visualizar</button>
                        </IonCol>
                        <IonCol size="2" className="flex justify-center align-center font-1-2">
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

export default CardPagina