import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export interface Pagina {
  id: number;
  titulo: string;
  texto: string;
  inicial: number;
}

export interface Paginas {
  data: [
    {
      id: number;
      titulo: string;
      texto: string;
      inicial: number;
    }
  ];
  last_page: number;
}

export async function getPaginas(
  page: number,
  params: string
): Promise<Paginas> {
  const subconta = JSON.parse(localStorage.getItem("@auth:subconta") || "");
  var config: object = {
    method: "get",
    url: `${API_URL}/paginas?page=${page}${params}&subconta=${subconta.id}`,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("@auth:token")}`,
    },
  };

  try {
    const resp = await axios(config);

    return {
      data: resp.data.data,
      last_page: resp.data.meta.last_page,
    };
  } catch (error) {
    throw new Error(String(error));
  }
}

export async function getPagina(id: number): Promise<Pagina> {
  const subconta = JSON.parse(localStorage.getItem("@auth:subconta") || "");
  var config: object = {
    method: "get",
    url: `${API_URL}/paginas/${id}&subconta=${subconta.id}`,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("@auth:token")}`,
    },
  };

  try {
    const resp = await axios(config);
    return resp.data;
  } catch (error) {
    throw new Error(String(error));
  }
}
