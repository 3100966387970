import React from 'react'
import { HiLockClosed } from 'react-icons/hi';
import { FaUser, FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

import '../assets/css/components/input.css'

interface inputProps{
    label: string,
    name: string,
    type: string,
    icon: string,
    background: string,
    value: string,
    onchange: Function
}

const Input: React.FC<inputProps> = ( props ) => {
    const back = props.background === 'true';

    const renderIcon = () => {
        switch (props.icon) {
            case 'email':
                return( <MdEmail className="icone" /> );
            case 'lock':
                return( <HiLockClosed className="icone" /> );
            case 'person':
                return( <FaUser className="icone" /> );
            case 'phone':
                return( <FaPhoneAlt className="icone" /> );
            default:
                break;
        }
    }

    return(
        <>
            <div className={'label-float primary-font '+(!back ? 'no-background' : '')}>
                <div className="icon-area">
                    {renderIcon()}
                </div>
                <div className="input-area">
                    <input autoComplete="off" placeholder=" " type={props.type} name={props.name} id={props.name} value={props.value} onChange={(e:any) => props.onchange(e.target.value) } />
                    <label>{props.label}</label>
                </div>
            </div>
        </>
    )
}

export default Input