import { IonCol, IonContent, IonDatetime, IonGrid, IonModal, IonPage, IonRow, IonToast } from '@ionic/react';
import React, { useEffect, useContext, useState, useCallback } from 'react';
import {Document, Page, pdfjs} from 'react-pdf'
import { IoIosPaper } from 'react-icons/io';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaRegSadTear, FaFilter } from 'react-icons/fa';
import { VscChromeClose } from 'react-icons/vsc';
import moment from 'moment';

import Navbar from '../components/Navbar';
import CardDoc from '../components/CardDoc';
import ButtonForm from '../components/ButtonForm';
import AuthContext from './../contexts/auth';

import { getDocuments, downloadDoc, downloadMultiDocs, getDocExt } from './../services/documents';
import { getDocumentoTipos, getEmpresas } from './../services/helper';

import '../assets/css/documentos.css';
import {FooterExample} from "../components/Footer";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Documento{
    id: number;
    nome: string;
    tipo: string;
    data_referencia: string;
    url: string;
}
interface Tipo{
    id: number;
    tipo: string;
}
interface Empresa{
    id: number;
    empresa: string;
}

const Documentos: React.FC = () => {
    moment.locale('pt-br')

    const [page, setPage] = useState<number>(1);
    const [lastPage, setLastPage] = useState<number>(1)
    const [documents, setDocuments] = useState<Array<Documento>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingOverlay, setLoadingOverlay] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [showToastSuccess, setShowToastSuccess] = useState<boolean>(false);
    const [toastErrorMsg, setToastErrorMsg] = useState<string>("");
    const [showToastError, setShowToastError] = useState<boolean>(false);
    const [showToastLoading, setShowToastLoading] = useState<boolean>(false);

    const { setExpired, refreshToken } = useContext(AuthContext)

    const [showModalFiltro, setShowModalFiltro] = useState<boolean>(false);
    const [showModalDoc, setShowModalDoc] = useState<boolean>(false);

    const [startDate, setStartDate] = useState<string>(moment().format());
    const [endDate, setEndDate] = useState<string>(moment().format());

    const [docTypes, setDocTypes] = useState<Array<Tipo>>([]);
    const [selectedDocTypes, setSelectedDocTypes] = useState<Array<string>>([])
    const [loadingDocTypes, setLoadingDocTypes] = useState<boolean>(true);
    
    const [empresas, setEmpresas] = useState<Array<Empresa>>([]);
    const [selectedEmpresas, setSelectedEmpresas] = useState<Array<string>>([])
    const [loadingEmpresas, setLoadingEmpresas] = useState<boolean>(true);

    const [selectedDocs, setSelectedDocs] = useState<Array<{id:number,titulo:string, url:string}>>([]);
    const [showButton, setShowButton] = useState<boolean>(false)
    const [docToView, setDocToView] = useState<string>("");

    const [params, setParams] = useState<string>("");

    const [numPages, setNumPages] = useState(1);
    let pages:Array<any> = [];

    function onDocumentLoadSuccess({ numPages }:any) {
        setNumPages(numPages);
    }

    for(let i = 1; i <= numPages; i++){
        pages.push(<Page key={i} renderMode="canvas" className="doc-page" pageNumber={i} />)
    }

    const downloadDocument = async (url:string, titulo:string) => {
        setShowToastLoading(true);
        try {
            let formatedTitle = titulo.replace(/-/g,' ')
            .replace(/,/g,' ')
            .replace(/_/g,' ')
            .replace(/ +/g,'-')
            formatedTitle = formatedTitle.toLocaleLowerCase();
            let resp = await downloadDoc(url, formatedTitle);
            console.log(resp)
            setShowToastLoading(false)
            setShowToastSuccess(true);
        } catch (e) {
            setShowToastLoading(false)
            setToastErrorMsg(e);
            setShowToastError(true);
        }
    }

    const loadDocuments = useCallback(async () => {
        try {
            setLoading(true);
            const resp = await getDocuments(page, params);
            setLastPage(resp.last_page);
            setDocuments((prev:Array<Documento>) => [...prev, ...resp.data]);
            setLoading(false)
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    },[page,params]) 
    
    // Monitor the page state, every time page changes this function get the current page from api
    useEffect(() => {
        const expire_date = new Date(localStorage.getItem('@auth:expires_in') || "");
        const now = new Date(moment().format());
        if(now.getTime() > expire_date.getTime()){
            console.log("Expirou")
            setExpired()
        }else{
            loadDocuments();
        }
    }, [loadDocuments, refreshToken,setExpired]);

    // When the screen is scrolled to the bottom the page state is increased
    const handleScroll = (e:CustomEvent<any>) => {
        const scrollTop = e.detail.scrollTop;
        const { clientHeight, scrollHeight } = e.detail.event.path[0]

        if (Math.floor(scrollHeight - scrollTop) === clientHeight) {
            if(page < lastPage)
            setPage(prev => prev + 1);
        }
    };
    
    async function openFilter(){
        setLoadingDocTypes(true)
        setLoadingEmpresas(true)

        setShowModalFiltro(true)

        const tiposdoc = await getDocumentoTipos();
        const empresasresp = await getEmpresas();

        setEmpresas(empresasresp.data);
        setDocTypes(tiposdoc.data);

        setLoadingDocTypes(false);
        setLoadingEmpresas(false);
    }

    function selectDocType(e:any){
        if(e.target.classList.contains('active')){
            e.target.classList.remove("active")
            setSelectedDocTypes(selectedDocTypes.filter(item => item !== e.target.getAttribute('data-id')))
        }else{
            e.target.classList.add("active")
            setSelectedDocTypes([...selectedDocTypes, e.target.getAttribute('data-id')]);
        }
    }

    function selectEmpresa(e:any){
        if(e.target.classList.contains('active')){
            e.target.classList.remove("active")
            setSelectedEmpresas(selectedEmpresas.filter(item => item !== e.target.getAttribute('data-id')))
        }else{
            e.target.classList.add("active")
            setSelectedEmpresas([...selectedEmpresas, e.target.getAttribute('data-id')]);
        }
    }

    function filterDocuments(){
        setParams("");
        let query = "";
        query = query + `&periodo_de=${moment(startDate).format('MM/YYYY')}`;
        query = query + `&periodo_ate=${moment(endDate).format('MM/YYYY')}`;
        if(selectedDocTypes.length > 0)
            selectedDocTypes.map((item) => query = query + `&documento_tipos[]=${item}`)
        if(selectedEmpresas.length > 0)
            selectedEmpresas.map((item) => query = query + `&grupos[]=${item}`)
        
        setDocuments([]);
        setLoading(true);
        setShowModalFiltro(false)
        setParams(query);
        setPage(1);
    }

    function handleCheckbox(id:number,titulo:string,url:string, targetChecked:boolean){
        if(!targetChecked)
            setSelectedDocs(selectedDocs.filter(item => item.id !== id))
        else
            setSelectedDocs([...selectedDocs, {id,titulo,url}]);
    }

    async function donwloadMultDocuments(){
        setShowToastLoading(true);
        try {
            let formatedData = selectedDocs.map(item => {
                item.titulo = item.titulo.replace(/-/g,' ')
                .replace(/,/g,' ')
                .replace(/_/g,' ')
                .replace(/ +/g,'-');
                item.titulo = item.titulo.toLocaleLowerCase();

                return item;
            })

            console.log(formatedData)
            await downloadMultiDocs(formatedData);
            setShowToastLoading(false)
            setShowToastSuccess(true);
        } catch (e) {
            setShowToastLoading(false)
            setToastErrorMsg(e)
            setShowToastError(true);
        }
    }

    async function openModalDoc(url:string, titulo:string){
        setLoadingOverlay(true)
        const resp = await getDocExt(url)
        setLoadingOverlay(false)
        if(resp === '.pdf'){
            url = url + `?token=${localStorage.getItem('@auth:token')}`
            setDocToView(url);
            setShowModalDoc(true)
        }else{
            downloadDocument(url, titulo)
        }
    }

    useEffect(() => {
        setShowButton(selectedDocs.length > 1 ? true : false);
    },[selectedDocs])

    return(
        <IonPage className="common-pages">
            <IonContent fullscreen scrollEvents={true} onIonScroll={handleScroll}>
            {loadingOverlay && <div className="overlay"><h5 className="primary-font text-aster-red font-1-3 ion-text-center"><AiOutlineLoading3Quarters className="spin" /></h5></div>}
            {
                showModalDoc === true && <style>{"\
                                    .modal-wrapper{\
                                        height:100vh !important;\
                                        width:100% !important;\
                                    }\
                                "}</style>
            }

                <IonModal isOpen={showModalFiltro} cssClass='text-black' onDidDismiss={()=>setShowModalFiltro(false)}>
                    <div className="header-modal-filtro flex flex-row justify-space-between">
                        <h1 className="primary-font text-white font-big">FILTRAR</h1>
                        <VscChromeClose style={{'marginTop':'10px'}} className="text-white font-extra-big" onClick={() => setShowModalFiltro(false)} />
                    </div>
                    <div className="content-modal-filtro">
                        <IonGrid>
                            <IonRow>
                                <IonCol size="10" offset="1">
                                    <label className="primary-font text-gray font-small">Período</label>
                                </IonCol>
                                <IonCol size="10" offset="1" className="flex align-center" style={{'justifyContent':'space-around'}}>
                                    <div className="datepicker primary-font font-medium">
                                        <IonDatetime 
                                            className="primary-font"
                                            monthShortNames="Jan, Fev, Mar, Abr, Mai, Jun, Jul, Ago, Set, Out, Nov, Dez"
                                            displayFormat="MMM/YYYY" 
                                            placeholder="Select Date" 
                                            value={startDate}
                                            doneText="Feito"
                                            cancelText="Cancela"
                                            onIonChange={e => setStartDate(e.detail.value!)}></IonDatetime>
                                    </div>
                                    <span style={{'margin':'0'}} className="primary-font font-small text-aster-red">Até</span>
                                    <div className="datepicker primary-font font-medium">
                                        <IonDatetime 
                                            className="primary-font"
                                            monthShortNames="Jan, Fev, Mar, Abr, Mai, Jun, Jul, Ago, Set, Out, Nov, Dez"
                                            displayFormat="MMM/YYYY" 
                                            placeholder="Select Date" 
                                            value={endDate}
                                            doneText="Feito"
                                            cancelText="Cancela"
                                            onIonChange={e => setEndDate(e.detail.value!)}></IonDatetime>
                                    </div>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="10" offset="1" style={{'marginTop':'20px'}}>
                                    <label className="primary-font text-gray font-small">Tipo</label>
                                </IonCol>
                                <IonCol size="10" offset="1">
                                    <div className="flex flex-wrap">
                                    {
                                        docTypes.map((type, index) => {
                                            let isActive = selectedDocTypes.includes((type.id).toString());
                                            return(
                                                <div onClick={selectDocType} className={"label-opt" + (isActive ? ' active' : '')} key={index} data-id={type.id} id={`documento-tipo-${type.id}`}>{type.tipo}</div>
                                            )
                                        })
                                    }
                                    </div>
                                    {loadingDocTypes && <h5 className="primary-font text-aster-red font-1-3 ion-text-center"><AiOutlineLoading3Quarters className="spin" /></h5>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="10" offset="1" style={{'marginTop':'10px'}}>
                                    <label className="primary-font text-gray font-small">Empresas</label>
                                </IonCol>
                                <IonCol size="10" offset="1">
                                    <div className="flex flex-wrap">
                                    {
                                        empresas.map((empresa, index) => {
                                            let isActive = selectedEmpresas.includes((empresa.id).toString());
                                            return(
                                                <div onClick={selectEmpresa} className={"label-opt" + (isActive ? ' active' : '')} key={index} data-id={empresa.id} id={`empresa-${empresa.id}`}>{empresa.empresa}</div>
                                            )
                                        })
                                    }
                                    </div>
                                    {loadingEmpresas && <h5 className="primary-font text-aster-red font-1-3 ion-text-center"><AiOutlineLoading3Quarters className="spin" /></h5>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="10" offset="1">
                                    <ButtonForm onclick={filterDocuments} text="Aplicar filtro" />
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </IonModal>

                <IonModal isOpen={showModalDoc} cssClass='text-black' onDidDismiss={()=>setShowModalDoc(false)}>
                    <div className="header-modal-filtro flex flex-row justify-space-between">
                        <h1 className="primary-font text-white font-big">Visualizar</h1>
                        <VscChromeClose style={{'marginTop':'10px'}} className="text-white font-extra-big" onClick={() => setShowModalDoc(false)} />
                    </div>
                    <div className="content-modal-filtro">
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12" sizeLg="6" offset="0" offsetLg="3">
                                    <Document
                                        className="doc-viewer"
                                        externalLinkTarget="_blank"
                                        file={docToView}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        {pages.length === 0 && <h5 className="primary-font text-aster-red font-1-3 ion-text-center"><AiOutlineLoading3Quarters className="spin" /></h5>}
                                        {pages}
                                    </Document>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </IonModal>

                <Navbar />
                <div className="page-title-container">
                    <div className="page-title justify-space-between">
                        <div className="flex justify-left">
                            <span className="font-big text-aster-red bold"><IoIosPaper/></span>&nbsp;
                            <span className="title-text primary-font font-1-2 text-aster-red bold">Documentos</span>
                        </div>
                        <span onClick={openFilter} className="font-medium text-gray align-center justify-center pointer"><FaFilter /> Filtrar</span>
                    </div>
                </div>
                <IonGrid>
                    <IonRow style={{paddingBottom:`${showButton ? '60px' : '0px'}`}}>
                        <IonCol size="12" sizeMd="10" sizeXl="8" offsetMd="1" offsetXl="2">
                            <ul className="container-doc-card">
                                {
                                    documents.map((doc,index) => {
                                        return(
                                            <li key={index}>
                                                <CardDoc 
                                                    tipo={doc.tipo || ""}
                                                    titulo={doc.nome}
                                                    dataRef={doc.data_referencia}
                                                    url={doc.url}
                                                    id={doc.id}
                                                    clickDownload={downloadDocument}
                                                    viewDoc={openModalDoc}
                                                    onchange={handleCheckbox}
                                                />
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            {documents.length === 0 && !loading && <h1 className="primary-font text-aster-red font-medium ion-text-center">Nenhum resultado encontrado.</h1>}
                            {loading && <h5 className="primary-font text-aster-red font-1-3 ion-text-center"><AiOutlineLoading3Quarters className="spin" /></h5>}
                            {
                                error && 
                                <>
                                <h5 className="primary-font text-aster-red font-1-4 ion-text-center"><FaRegSadTear/></h5>
                                <h5 className="primary-font text-aster-red font-medium ion-text-center">Oops! Houve um erro no carregamento.</h5>
                                </>
                            }
                        </IonCol>
                        <IonCol style={{position:'fixed', bottom:'15px'}} size="10" sizeMd="6" sizeLg="4" offset="1" offsetMd="3" offsetLg="4">
                            <button onClick={donwloadMultDocuments} className={"btn-download-docs text-white primary-regular font-medium" + (showButton && ' show')}>Download dos arquivos selecionados</button>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonToast
                    isOpen={showToastLoading}
                    onDidDismiss={() => setShowToastLoading(false)}
                    message="Baixando..."
                    color="dark"
                    cssClass="text-white primary-font"
                />
                <IonToast
                    isOpen={showToastSuccess}
                    onDidDismiss={() => setShowToastSuccess(false)}
                    message="Documento baixado com sucesso!"
                    color="success"
                    duration={1000}
                    cssClass="text-white primary-font"
                />
                <IonToast
                    isOpen={showToastError}
                    onDidDismiss={() => setShowToastError(false)}
                    message={toastErrorMsg}
                    color="danger"
                    duration={1000}
                    cssClass="text-white primary-font"
                />
            </IonContent>
            <FooterExample />
      </IonPage>
    )
}

export default Documentos;
