import { IonContent, IonPage, IonSpinner, IonToast } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router";
import AuthContext from "../contexts/auth";

const LoginFacebook: React.FC = (props: any) => {
  const { signInSSO } = useContext(AuthContext);
  let history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [primeiroAcesso, setPrimeiroAcesso] = useState<boolean>(false);
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("")

  useEffect(() => {
    const facebookParams = props.location.search;

    handlerSignIn(facebookParams);

    async function handlerSignIn(searchString: string){
      if (loading) {
        return;
      }

      setLoading(true);

      try{
        await signInSSO(searchString, 'facebook');
        history.push('/');
      } catch(e){
        if (e.message === "primeiro_acesso") {
          setPrimeiroAcesso(true);
        } else {
          if (e.message === 'Nenhuma conta vinculada foi encontrada!') {
            return history.push('/?error=' + e.message);
          }
  
          setToastMessage(e.message);
          setShowToastError(true);
        }
      }
    }
  }, [props.location.search, history, signInSSO, loading]);

  if(primeiroAcesso)
    return <Redirect to={{pathname:'/primeiro-acesso'}}/>

  return (
    <IonPage>
      <IonContent fullscreen className="login-page">
        <div className="loading-login">
          <IonSpinner name="crescent" color="danger" />
        </div>
        <IonToast
          isOpen={showToastError}
          onDidDismiss={() => setShowToastError(false)}
          message={toastMessage}
          color="danger"
          duration={1300}
          cssClass="text-white primary-font"
        />
      </IonContent>
    </IonPage>
  );
};

export default LoginFacebook;
