import { 
    IonCol, 
    IonContent, 
    IonGrid, 
    IonPage, 
    IonRow,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonToast,
    IonSpinner
} from '@ionic/react';
import React, {FormEvent, useState} from 'react';
import { Redirect, useParams } from 'react-router-dom';
import ButtonBasic from '../components/ButtonBasic';

import Input from '../components/Input';
import { recoverPassword } from './../services/auth';

const RecuperarSenha: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [showToastSuccess, setShowToastSuccess] = useState<boolean>(false);
    const [showToastError, setShowToastError] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { t }:any = useParams();

    async function recuperarSenha(e:FormEvent){
        e.preventDefault()
        let passwordsAreEqual = password === confirmPassword

        if(!passwordsAreEqual){
            setErrorMessage("As senhas não coincidem");
            setShowToastError(true)
            return
        }

        setLoading(true);
        let data = {
            email,
            password,
            confirm_password: confirmPassword,
            token: t
        }
        try {
            await recoverPassword(data);
            setShowToastSuccess(true);
            setTimeout(() => {
                setRedirect(true);
            },2500); 
        } catch (error) {
            setErrorMessage(error);
            setShowToastError(true);
            // console.log(error)
        }
        setLoading(false);
    }

    if(redirect)
        return <Redirect to={{pathname:'/'}}/>

    return (
        <IonPage>
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow>
                        <IonCol size="10" sizeMd="4" sizeLg="2" offset="1" offsetMd="4" offsetLg="5">
                            <h4 className="ion-text-left primary-font"><strong>Recuperar Senha</strong></h4>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <form action="" method="post" onSubmit={(e:any) => recuperarSenha(e)}>

                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" sizeLg="6" sizeXl="4" offsetLg="3" offsetXl="4">
                                <div className="box-white">
                                    <IonRow>
                                        <IonCol size="12">
                                            <Input onchange={(value:string) => setEmail(value)} value={email} background="false" icon="email" name="email" type="email" label="E-MAIL" />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12">
                                            <Input onchange={(value:string) => setPassword(value)} value={password} background="false" icon="lock" name="password" type="password" label="SENHA" />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12">
                                            <Input onchange={(value:string) => setConfirmPassword(value)} value={confirmPassword} background="false" icon="lock" name="confirm_password" type="password" label="CONFIRME A SENHA" />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="mt-1" size="12">
                                            <ButtonBasic type="submit" onclick={() => {}} >
                                                {loading ? <IonSpinner className="text-white" name="crescent" /> : 'ENVIAR'}
                                            </ButtonBasic>
                                        </IonCol>
                                    </IonRow>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>
                <IonToast
                    isOpen={showToastSuccess}
                    onDidDismiss={() => setShowToastSuccess(false)}
                    message="Senha alterada com sucesso"
                    color="success"
                    duration={2000}
                    cssClass="text-white primary-font"
                />
                    <IonToast
                    isOpen={showToastError}
                    onDidDismiss={() => setShowToastError(false)}
                    message={errorMessage}
                    color="danger"
                    duration={2000}
                    cssClass="text-white primary-font"
                />
            </IonContent>
        </IonPage>
    );
};

export default RecuperarSenha;
