import { 
    IonCol, 
    IonContent, 
    IonGrid, 
    IonPage, 
    IonRow,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonToast,
    IonSpinner
} from '@ionic/react';
import React, {FormEvent, useState} from 'react';
import ButtonBasic from '../components/ButtonBasic';

import Input from '../components/Input';
import { sendForgotPasswordEmail } from './../services/auth';

const EsqueciSenha: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [showToastSuccess, setShowToastSuccess] = useState<boolean>(false);
    const [showToastError, setShowToastError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    async function enviarEmail(e:FormEvent){
        e.preventDefault()
        setLoading(true);
        try {
            await sendForgotPasswordEmail(email);
            setLoading(false);
            setEmail("");
            setShowToastSuccess(true);
        } catch (error) {
            setLoading(false);
            setShowToastError(true);
        }
    }

    return (
        <IonPage>
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow>
                        <IonCol size="10" sizeMd="4" sizeLg="2" offset="1" offsetMd="4" offsetLg="5">
                            <h4 className="ion-text-left primary-font"><strong>Qual o seu e-mail?</strong></h4>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <form action="" method="post" onSubmit={(e:any) => enviarEmail(e)}>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" sizeLg="6" sizeXl="4" offsetLg="3" offsetXl="4">
                                <div className="box-white">
                                    <IonRow>
                                        <IonCol size="12">
                                            <Input onchange={(value:string) => setEmail(value)} value={email} background="false" icon="email" name="email" type="email" label="E-MAIL" />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="mt-1" size="12">
                                            <ButtonBasic type="submit" disabled={loading && true} onclick={() => {}} >
                                                {loading ? <IonSpinner className="text-white" name="crescent" /> : 'ENVIAR'}
                                            </ButtonBasic>
                                        </IonCol>
                                    </IonRow>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>
                <IonToast
                    isOpen={showToastSuccess}
                    onDidDismiss={() => setShowToastSuccess(false)}
                    message="Foi enviado para o seu e-mail uma mensagem com o link de recuperação de senha."
                    color="success"
                    duration={5000}
                    cssClass="text-white primary-font"
                />
                    <IonToast
                    isOpen={showToastError}
                    onDidDismiss={() => setShowToastError(false)}
                    message="E-mail inválido!"
                    color="danger"
                    duration={2000}
                    cssClass="text-white primary-font"
                />
            </IonContent>
        </IonPage>
    );
};

export default EsqueciSenha;
