import React from 'react';

import '../assets/css/components/checkbox-basic.css';

interface CheckboxBasicProps {
    name?:string;
    label:string;
    value?:string;
    checked: boolean;
    onchange: Function
}

const CheckboxBasic: React.FC<CheckboxBasicProps> = (props) => {
    return(
        <>
            <label className="checkboxbasic-container primary-font font-medium">{props.label}
                <input onChange={(e:any) => props.onchange(props.value,e.target.name, e.target.checked)} name={props.name} value={props.value} type="checkbox" defaultChecked={props.checked} />
                <span className="checkmark"></span>
            </label>
        </>
    )
}

export default CheckboxBasic;