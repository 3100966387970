import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

export async function register(data:object):Promise<{message:string}>{
    var config:object = {
        method: 'post',
        url: `${API_URL}/cadastrar`,
        headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('@auth:token')}`
        },
        data: data
    };

    try{
        await axios(config);
        return{
            message: "OK"
        };
    }catch(error){ 
        throw error.response.data.errors;
    }
}
