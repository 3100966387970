import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  IonModal,
  IonSpinner,
} from "@ionic/react";
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  FormEvent,
} from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaRegSadTear, FaLock } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
import moment from "moment";

import Navbar from "../components/Navbar";
import PersonCard from "../components/PersonCard";
import Modal from "../components/Modal";
import TextField from "../components/TextField";
import MaskedTextField from "../components/MaskedTextField";
import Radio from "../components/Radio";
import ButtonForm from "../components/ButtonForm";

import {
  getPeople,
  deletePerson,
  registerPerson,
  getPersonById,
  editPerson,
} from "./../services/people";
import AuthContext from "./../contexts/auth";

import "../assets/css/pessoas.css";
import { FooterExample } from "../components/Footer";

interface Person {
  id: number;
  nome: string;
  ativo: number;
  cadastro_tipo: {
    id: number;
    tipo: string;
  };
}
interface Tipo {
  id: number;
  tipo: string;
}
interface Errors {
  nome: [];
  email: [];
  data_cadastro: [];
  password: [];
  horario: [];
  password_confirm: [];
  cpf: [];
}

const Pessoas: React.FC = () => {
  moment.locale("pt-br");

  const { setExpired, refreshToken, user } = useContext(AuthContext);

  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [people, setPeople] = useState<Array<Person>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [loadingCadastro, setLoadingCadastro] = useState<boolean>(false);
  const [registerErrors, setRegisterErrors] = useState({} as Errors);
  const [edit, setEdit] = useState<boolean>(false);

  // States of Delete Modal
  const [showModal, setShowModal] = useState<boolean>(false);
  const [nomePessoa, setNomePessoa] = useState<string>("");
  const [idPessoa, setIdPessoa] = useState<number>(0);

  // States of Register Modal
  const [showModalCadastro, setShowModalCadastro] = useState<boolean>(false);
  const [nome, setNome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const [confirmaSenha, setConfirmaSenha] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [rg, setRg] = useState<string>("");
  const [telefone, setTelefone] = useState<string>("");
  const [celular, setCelular] = useState<string>("");
  const [dtNasc, setDtNasc] = useState<string>("");
  const [horario, setHorario] = useState<string>("");
  const [dtCadastro, setDtCadastro] = useState<string>("");
  const [controleVeic, setControleVeic] = useState<string>("1");
  const [documentoTipos, setDocumentoTipos] = useState<Array<string>>([]);
  const [ativo, setAtivo] = useState<string>("1");
  const [financeiro, setFinanceiro] = useState<string>("1");
  const [acessoPortal, setAcessoPortal] = useState<string>("1");
  const [dispararEmail, setDispararEMail] = useState<string>("1");

  // Toasts
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showToastSuccess, setShowToastSuccess] = useState<boolean>(false);
  const [showToastError, setShowToastError] = useState<boolean>(false);

  const loadPeople = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await getPeople(page);
      setLastPage(resp.last_page);
      setPeople((prev: Array<Person>) => [...prev, ...resp.data]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, [page]);

  // Monitor the page state, every time page changes this function get the current page from api
  useEffect(() => {
    const expire_date = new Date(
      localStorage.getItem("@auth:expires_in") || ""
    );
    const now = new Date(moment().format());
    if (now.getTime() > expire_date.getTime()) {
      console.log("Expirou");
      setExpired();
    } else {
      loadPeople();
    }
  }, [loadPeople, refreshToken, setExpired]);

  // When the screen is scrolled to the bottom the page state is increased
  const handleScroll = (e: CustomEvent<any>) => {
    const scrollTop = e.detail.scrollTop;
    const { clientHeight, scrollHeight } = e.detail.event.path[0];

    if (Math.floor(scrollHeight - scrollTop) === clientHeight) {
      if (page < lastPage) setPage((prev) => prev + 1);
    }
  };

  async function getUserById(id: number) {
    setEdit(true);
    const resp = await getPersonById(id);

    setIdPessoa(resp.id);
    setNome(resp.nome || "");
    setEmail(resp.email || "");
    setCpf(resp.cpf || "");
    setRg(resp.rg || "");
    setTelefone(resp.telefone || "");
    setCelular(resp.celular || "");
    setDtNasc(resp.data_nascimento || "");
    setHorario(moment(resp.horario).format("HH:mm") || "");
    setDtCadastro(moment(resp.data_cadastro).format("DD/MM/YYYY") || "");
    setControleVeic(resp.controle_veicular);
    setAtivo(resp.ativo);
    setAcessoPortal(resp.acesso_portal);

    let docTipos = resp.documento_tipos.map((item: any) => item.id.toString());
    setDocumentoTipos(docTipos);

    openModalCadastro();
  }

  async function editUser(e: FormEvent) {
    e.preventDefault();

    let subConta = null;

    if (localStorage.getItem("@auth:subconta")) {
      subConta = JSON.parse(String(localStorage.getItem("@auth:subconta")));
    }

    const data = {
      nome,
      email,
      password: senha,
      password_confirm: confirmaSenha,
      subconta: subConta?.id,
      cpf,
      rg,
      telefone,
      celular,
      data_nascimento: dtNasc,
      horario,
      controle_veicular: controleVeic,
      data_cadastro: dtCadastro,
      documento_tipos: documentoTipos,
      ativo,
      financeiro,
      acesso_portal: acessoPortal,
      disparar_email: dispararEmail,
    };

    setLoadingCadastro(true);
    try {
      await editPerson(data, idPessoa);
      setLoadingCadastro(false);
      clearFields();
      setShowModalCadastro(false);
      setPeople([]);
      setEdit(false);
      setToastMessage("Pessoa editada com sucesso");
      setShowToastSuccess(true);
      setPage(1);
      loadPeople();
    } catch (error) {
      console.log(error);
      setRegisterErrors(error);
      document.querySelector(".content-modal-cadastro")?.scrollTo(0, 0);
      setLoadingCadastro(false);
    }
  }

  // Open delete Modal
  function openDeleteModal(id: number, name: string) {
    setNomePessoa(name);
    setIdPessoa(id);
    setShowModal(true);
  }

  async function openModalCadastro() {
    setShowModalCadastro(true);
    setDtCadastro(moment().format("DD/MM/YYYY"));
  }

  function closeModalCadastro() {
    setEdit(false);
    setRegisterErrors({} as Errors);
    setShowModalCadastro(false);
    clearFields();
  }

  // Call the function to delete the user
  async function deleteUser() {
    try {
      const resp = await deletePerson(idPessoa);
      if (resp.success) {
        setToastMessage("Pessoa deletada com sucesso");
        setShowToastSuccess(true);
      }
    } catch (e) {
      setShowToastError(true);
    }
    setShowModal(false);
    setPeople([]);
    setPage(1);
    loadPeople();
  }

  async function sendFormCadastro(e: FormEvent) {
    let subConta = null;

    if (localStorage.getItem("@auth:subconta")) {
      subConta = JSON.parse(String(localStorage.getItem("@auth:subconta")));
    }

    e.preventDefault();
    const data = {
      nome,
      email,
      password: senha,
      password_confirm: confirmaSenha,
      subconta: subConta.id,
      cpf,
      rg,
      telefone,
      celular,
      data_nascimento: dtNasc,
      horario,
      controle_veicular: controleVeic,
      data_cadastro: dtCadastro,
      documento_tipos: documentoTipos,
      ativo,
      financeiro,
      acesso_portal: acessoPortal,
      disparar_email: dispararEmail,
    };

    setLoadingCadastro(true);
    try {
      await registerPerson(data);
      setLoadingCadastro(false);
      clearFields();
      setShowModalCadastro(false);
      setPeople([]);
      setToastMessage("Pessoa cadastrada com sucesso");
      setShowToastSuccess(true);
      setPage(1);
      loadPeople();
    } catch (error) {
      setRegisterErrors(error);
      document.querySelector(".content-modal-cadastro")?.scrollTo(0, 0);
      setLoadingCadastro(false);
    }
  }

  // function handleSubcontas(id:string, targetName:string, targetChecked:boolean){
  //   if(!targetChecked)
  //     setSelectedSubContas(selectedSubContas.filter(item => item !== id))
  //   else
  //     setSelectedSubContas([...selectedSubContas, id]);
  // }

  function clearFields() {
    setNome("");
    setEmail("");
    setSenha("");
    setCpf("");
    setRg("");
    setTelefone("");
    setCelular("");
    setDtNasc("");
    setHorario("");
    setDtCadastro("");
    setControleVeic("1");
    setDocumentoTipos([]);
    setAtivo("1");
    setFinanceiro("1");
    setAcessoPortal("1");
    setDispararEMail("1");
  }

  return (
    <IonPage className="common-pages">
      <IonContent fullscreen scrollEvents={true} onIonScroll={handleScroll}>
        {/* Delete Modal */}
        <Modal class={showModal ? "flex" : "hide"}>
          <div className="flex flex-column align-center ion-text-center">
            <p className="primary-font text-gray">
              Tem certeza que deseja excluir o cadastro de
            </p>
            <h3 className="primary-regular text-aster-red">"{nomePessoa}"</h3>
            <button
              onClick={() => setShowModal(false)}
              className="btn-modal cancelar primary-regular font-medium ion-text-center"
            >
              Cancelar
            </button>
            <button
              onClick={() => deleteUser()}
              className="btn-modal action primary-regular font-medium ion-text-center"
            >
              Excluir
            </button>
          </div>
        </Modal>

        {/* Register Modal */}
        <IonModal
          isOpen={showModalCadastro}
          cssClass="text-black"
          onDidDismiss={closeModalCadastro}
        >
          {loadingCadastro ? (
            <div className="loading-login">
              <IonSpinner name="crescent" color="danger" />
            </div>
          ) : (
            ""
          )}
          <div className="header-modal-cadastro flex flex-row justify-space-between">
            <h1 className="primary-font text-white font-big">
              {edit ? "Edição de pessoa" : "Cadastro de pessoa"}
            </h1>
            <VscChromeClose
              style={{ marginTop: "10px" }}
              className="text-white font-big"
              onClick={() => setShowModalCadastro(false)}
            />
          </div>
          <div className="content-modal-cadastro">
            <form
              onSubmit={(e: any) => (edit ? editUser(e) : sendFormCadastro(e))}
              action=""
              method="post"
            >
              <IonRow>
                <IonCol size="10" offset="1">
                  <div className="errors primary-font text-aster-red">
                    <ul>
                      {registerErrors?.nome &&
                        registerErrors.nome.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      {registerErrors?.email &&
                        registerErrors.email.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      {registerErrors?.password &&
                        registerErrors.password.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      {registerErrors?.data_cadastro &&
                        registerErrors.data_cadastro.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      {registerErrors?.horario &&
                        registerErrors.horario.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      {registerErrors?.password_confirm &&
                        registerErrors.password_confirm.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      {registerErrors?.cpf &&
                        registerErrors.cpf.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                    </ul>
                  </div>
                </IonCol>
                <IonCol size="10" offset="1">
                  <TextField
                    required={true}
                    onchange={(value: string) => setNome(value)}
                    value={nome}
                    label="Nome"
                    type="text"
                    name="nome"
                    placeholder="Digite o nome"
                  />
                </IonCol>
                <IonCol
                  size="10"
                  offset="1"
                  style={{ display: acessoPortal === "1" ? "block" : "none" }}
                >
                  <TextField
                    required={acessoPortal === "1"}
                    onchange={(value: string) => setEmail(value)}
                    value={email}
                    label="E-mail"
                    type="email"
                    name="email"
                    placeholder="Digite o e-mail"
                  />
                </IonCol>
                <IonCol
                  size="10"
                  offset="1"
                  style={{ display: acessoPortal === "1" ? "block" : "none" }}
                >
                  <TextField
                    required={acessoPortal === "1"}
                    onchange={(value: string) => setSenha(value)}
                    value={senha}
                    label="Senha"
                    type="password"
                    name="senha"
                    placeholder="Digite a senha"
                  />
                </IonCol>
                <IonCol
                  size="10"
                  offset="1"
                  style={{ display: acessoPortal === "1" ? "block" : "none" }}
                >
                  <TextField
                    required={acessoPortal === "1"}
                    onchange={(value: string) => setConfirmaSenha(value)}
                    value={confirmaSenha}
                    label="Confirmar senha"
                    type="password"
                    name="senha"
                    placeholder="Confirme a senha"
                  />
                </IonCol>
                <IonCol size="5" offset="1">
                  <MaskedTextField
                    mask="999.999.999-99"
                    onchange={(value: string) => setCpf(value)}
                    value={cpf}
                    label="CPF"
                    type="text"
                    name="cpf"
                    placeholder="Digite o CPF"
                  />
                </IonCol>
                <IonCol size="5">
                  <TextField
                    onchange={(value: string) => setRg(value)}
                    value={rg}
                    label="RG"
                    type="text"
                    name="rg"
                    placeholder="Digite o RG"
                  />
                </IonCol>
                <IonCol size="10" sizeLg="5" offset="1" offsetLg="1">
                  <MaskedTextField
                    mask="(99) 99999-9999"
                    onchange={(value: string) => setTelefone(value)}
                    value={telefone}
                    label="Telefone"
                    type="text"
                    name="telefone"
                    placeholder="Digite o telefone"
                  />
                </IonCol>
                <IonCol size="10" sizeLg="5" offset="1" offsetLg="0">
                  <MaskedTextField
                    mask="(99) 99999-9999"
                    onchange={(value: string) => setCelular(value)}
                    value={celular}
                    label="Celular"
                    type="text"
                    name="celular"
                    placeholder="Digite o celular"
                  />
                </IonCol>
                <IonCol size="5" offset="1">
                  <MaskedTextField
                    mask="99/99/9999"
                    onchange={(value: string) => setDtNasc(value)}
                    value={dtNasc}
                    label="Data de nascimento"
                    type="text"
                    name="dt_nasc"
                    placeholder="Data de nascimento"
                  />
                </IonCol>
                <IonCol size="5">
                  <MaskedTextField
                    mask="99:99"
                    onchange={(value: string) => setHorario(value)}
                    value={horario}
                    label="Horário"
                    type="text"
                    name="horario"
                    placeholder="Digite o horario"
                  />
                </IonCol>
                <IonCol size="5" offset="1">
                  <MaskedTextField
                    mask="99/99/9999"
                    onchange={(value: string) => setDtCadastro(value)}
                    value={dtCadastro}
                    label="Data Cadastro"
                    type="text"
                    name="data_cadastro"
                    placeholder="Digite a data"
                  />
                </IonCol>
                <IonCol size="5" offset="1">
                  <label className="primary-font text-aster-red font-small">
                    Ativo
                  </label>
                  <Radio
                    onchange={(value: string) => setAtivo(value)}
                    checked={ativo ? true : false}
                    label="Sim"
                    name="ativo"
                    value="1"
                  />
                  <Radio
                    onchange={(value: string) => setAtivo(value)}
                    checked={!ativo ? true : false}
                    label="Não"
                    name="ativo"
                    value="0"
                  />
                </IonCol>
                <IonCol size="5" offset="1">
                  <label className="primary-font text-aster-red font-small">
                    Acesso ao portal
                  </label>
                  <Radio
                    onchange={(value: string) => setAcessoPortal(value)}
                    checked={acessoPortal ? true : false}
                    label="Sim"
                    name="acesso_portal"
                    value="1"
                  />
                  <Radio
                    onchange={(value: string) => setAcessoPortal(value)}
                    checked={!acessoPortal ? true : false}
                    label="Não"
                    name="acesso_portal"
                    value="0"
                  />
                </IonCol>
                <IonCol size="5">
                  <label className="primary-font text-aster-red font-small">
                    Disparar e-mail
                  </label>
                  <Radio
                    onchange={(value: string) => setDispararEMail(value)}
                    checked={true}
                    label="Sim"
                    name="disparar_email"
                    value="1"
                  />
                  <Radio
                    onchange={(value: string) => setDispararEMail(value)}
                    checked={false}
                    label="Não"
                    name="disparar_email"
                    value="0"
                  />
                </IonCol>
                <IonCol size="10" offset="1">
                  <ButtonForm onclick={() => {}} text="Cadastrar" />
                </IonCol>
              </IonRow>
            </form>
          </div>
        </IonModal>

        {/* Navbar */}
        <Navbar />

        <div className="page-title-container">
          <div className="page-title justify-space-between">
            <span className="primary-font font-medium text-aster-red bold">
              Lista de contatos
            </span>
            {(user?.cadastro_tipo === null ||
              user?.cadastro_tipo?.id === 5) && (
              <button
                onClick={openModalCadastro}
                className="button-action primary-font font-small bold text-black"
              >
                CADASTRAR
              </button>
            )}
          </div>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="10" sizeXl="8" offsetMd="1" offsetXl="2">
              <ul className="container-person-card">
                {people.map((person, index) => {
                  return (
                    <li key={index}>
                      <PersonCard
                        type_id={
                          person.cadastro_tipo !== null
                            ? person.cadastro_tipo?.id
                            : 0
                        }
                        id_person={person.id}
                        edit={getUserById}
                        delete={openDeleteModal}
                        type={
                          person.cadastro_tipo !== null
                            ? person.cadastro_tipo?.tipo
                            : ""
                        }
                        name={person.nome}
                      />
                    </li>
                  );
                })}
              </ul>
              {loading && (
                <h5 className="primary-font text-aster-red font-1-3 ion-text-center">
                  <AiOutlineLoading3Quarters className="spin" />
                </h5>
              )}
              {error && user?.cadastro_tipo?.id === 5 && (
                <>
                  <h5 className="primary-font text-aster-red font-1-4 ion-text-center">
                    <FaRegSadTear />
                  </h5>
                  <h5 className="primary-font text-aster-red font-medium ion-text-center">
                    Oops! Houve um erro no carregamento.
                  </h5>
                </>
              )}
              {error && user?.cadastro_tipo?.id !== 5 && (
                <>
                  <h5 className="primary-font text-aster-red font-1-4 ion-text-center">
                    <FaLock />
                  </h5>
                  <h5 className="primary-font text-aster-red font-medium ion-text-center">
                    Você não possui permissão para visualizar esse conteúdo.
                  </h5>
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* Toasts */}
        <IonToast
          isOpen={showToastSuccess}
          onDidDismiss={() => setShowToastSuccess(false)}
          message={toastMessage}
          color="success"
          duration={1000}
          cssClass="text-white primary-font"
        />
        <IonToast
          isOpen={showToastError}
          onDidDismiss={() => setShowToastError(false)}
          message="Não foi possível completar a operação!"
          color="danger"
          duration={1000}
          cssClass="text-white primary-font"
        />
      </IonContent>
      <FooterExample />
    </IonPage>
  );
};

export default Pessoas;
