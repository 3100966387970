import { 
    IonCol, 
    IonContent, 
    IonGrid, 
    IonPage, 
    IonRow,
    IonRouterLink,
    IonToast,
    IonSpinner
} from '@ionic/react';
import React, { FormEvent, useContext, useEffect, useState } from 'react';

import Input from '../components/Input'
import ButtonBasic from '../components/ButtonBasic'

import '../assets/css/login.css';
import Logo from '../assets/images/logo_ASTER.png';
import ButtonSSO from '../components/ButtonSSO';

import AuthContext from './../contexts/auth';
import { Redirect, useHistory } from 'react-router-dom';

const Login: React.FC = (props: any) => {

  const { signIn, loading, setPass } = useContext(AuthContext);
  const [ email, setEmail ] = useState<string>("");
  const [ password, setPassword ] = useState<string>("");
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [primeiroAcesso, setPrimeiroAcesso] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("")
  const history = useHistory();

  useEffect(() => {
    if (props.location.search) {
      const urlParams = new URLSearchParams(props.location.search);
      if (urlParams.get('error')) {
        setToastMessage(urlParams.get('error') || 'Ocorreu um erro! Tente novamente mais tarde.');
        setShowToastError(true);
        history.push('/');
      }
    }
  }, [history, props.location.search]);

  async function handlerSignIn(e:FormEvent){
    e.preventDefault();
    setPass(password);
    try{
      await signIn(email,password);
    }catch(e){
      if(e.message === "primeiro_acesso"){
        setPrimeiroAcesso(true);
      }else{
        setToastMessage(e.message);
        setShowToastError(true);
      }
    }
  }

  if(primeiroAcesso)
    return <Redirect to={{pathname:'/primeiro-acesso'}}/>

  return (
    <IonPage>
      <IonContent fullscreen className="login-page">
        {loading ? <div className="loading-login"><IonSpinner name="crescent" color="danger"/></div> : ''}
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="8" sizeMd="4" sizeLg="2">
              <div className="login-screen-logo">
                <img src={Logo} alt="logo"/>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center">
            <IonCol>
              <h4 className="ion-text-center primary-regular">Faça o seu login</h4>
            </IonCol>
          </IonRow>
        </IonGrid>
        <form onSubmit={(e:any) => handlerSignIn(e)} action="" method="post">
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeLg="6" sizeXl="4" offsetLg="3" offsetXl="4">
                <div className="box-white">
                  <IonRow className="ion-align-items-center">
                    <IonCol size="12">
                      <Input onchange={(value:string) => setEmail(value)} value={email} background="true" icon="person" name="email" type="email" label="E-MAIL" />
                      <Input onchange={(value:string) => setPassword(value)} value={password} background="true" icon="lock" name="senha" type="password" label="SENHA" />
                      <ButtonBasic type="submit" onclick={() => {}} text="CONTINUAR" />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" className="ion-align-items-center ion-text-center">
                      <p className="primary-regular text-black font-extra-small">ESQUECEU A SENHA? <IonRouterLink routerLink="/esqueci-senha" routerDirection="forward" className="text-black"><u><strong>CLIQUE AQUI</strong></u></IonRouterLink></p>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <div className="separator text-gray primary-font font-extra-small">OU</div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <ButtonSSO opt="Facebook" />
                    </IonCol>
                    <IonCol size="12">
                      <ButtonSSO opt="Google" />
                    </IonCol>
                  </IonRow>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        {/* <IonGrid>
          <IonRow>
            <IonCol size="12" className="ion-align-items-center ion-text-center">
              <p className="primary-regular text-aster-red font-small">Primeiro acesso? <IonRouterLink routerLink="/primeiro-acesso" className="text-aster-red"><u><strong>Cadastre-se</strong></u></IonRouterLink></p>
            </IonCol>
          </IonRow>
        </IonGrid> */}
        <IonToast
          isOpen={showToastError}
          onDidDismiss={() => setShowToastError(false)}
          message={toastMessage}
          color="danger"
          duration={1300}
          cssClass="text-white primary-font"
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
