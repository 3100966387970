import React from "react";

import "../assets/css/components/text-field.css";

interface textFieldProps {
  label: string;
  name: string;
  type: string;
  placeholder: string;
  value: string;
  required?: boolean;
  onchange: Function;
}

const TextField: React.FC<textFieldProps> = (props) => {
  return (
    <>
      <div className="text-field-container">
        <label className="primary-font text-aster-red font-small">
          {props.label}
        </label>
        <input
          autoComplete="off"
          required={props.required}
          name={props.name}
          type={props.type}
          value={props.value}
          placeholder={props.placeholder}
          onChange={(e: any) => props.onchange(e.target.value)}
        />
      </div>
    </>
  );
};

export default TextField;
