import React from "react";
import InputMask from "react-input-mask";

import "../assets/css/components/text-field.css";

interface MaskedTextFieldProps {
  label: string;
  name: string;
  type: string;
  placeholder: string;
  value: string;
  mask: string;
  onchange: Function;
}

const MaskedTextField: React.FC<MaskedTextFieldProps> = (props) => {
  return (
    <>
      <div className="text-field-container">
        <label className="primary-font text-aster-red font-small">
          {props.label}
        </label>
        <InputMask
          mask={props.mask}
          name={props.name}
          type={props.type}
          value={props.value}
          placeholder={props.placeholder}
          onChange={(e: any) => props.onchange(e.target.value)}
        />
      </div>
    </>
  );
};

export default MaskedTextField;
