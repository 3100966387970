import React from 'react';
import { IonApp, IonRouterOutlet } from '@ionic/react';

import Routes from './routes';
import { AuthProvider } from './contexts/auth';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

// Custom CSS
import './assets/css/fonts.css'
import './assets/css/main.css'
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router';
import LoginGoogle from './pages/LoginGoogle';
import LoginFacebook from './pages/LoginFacebook';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-M9CNX34'
}

TagManager.initialize(tagManagerArgs)

const App: React.FC = () => (
  <AuthProvider>
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/login/google/callback" component={LoginGoogle} exact={true} />
          <Route path="/login/facebook/callback" component={LoginFacebook} exact={true} />
          <Routes />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  </AuthProvider>
);

export default App;
