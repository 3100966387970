import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useEffect, useContext, useState, useCallback } from "react";
import { IoMdPaper } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaRegSadTear } from "react-icons/fa";
import moment from "moment";

import Navbar from "../components/Navbar";
import AuthContext from "./../contexts/auth";

import CardPagina from "../components/CardPagina";
import { getPaginas, Pagina } from "../services/paginas";

const PaginasPage: React.FC = () => {
  moment.locale("pt-br");

  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [paginas, setPaginas] = useState<Pagina[]>([]);
  const [paginaInicial, setPaginaInicial] = useState<Pagina | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const { setExpired, refreshToken } = useContext(AuthContext);

  const [params] = useState<string>("");

  const loadPaginas = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await getPaginas(page, params);
      setLastPage(resp.last_page);
      setPaginas((prev: Pagina[]) => [
        ...prev,
        ...resp.data.filter((pagina) => !pagina.inicial),
      ]);
      const paginaInicial = resp.data.find((pagina) => pagina.inicial);
      if (paginaInicial) {
        setPaginaInicial(paginaInicial);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  }, [page, params]);

  // Monitor the page state, every time page changes this function get the current page from api
  useEffect(() => {
    const expire_date = new Date(
      localStorage.getItem("@auth:expires_in") || ""
    );
    const now = new Date(moment().format());
    if (now.getTime() > expire_date.getTime()) {
      console.log("Expirou");
      setExpired();
    } else {
      loadPaginas();
    }
  }, [loadPaginas, refreshToken, setExpired]);

  // When the screen is scrolled to the bottom the page state is increased
  const handleScroll = (e: CustomEvent<any>) => {
    const scrollTop = e.detail.scrollTop;
    const { clientHeight, scrollHeight } = e.detail.event.path[0];

    if (Math.floor(scrollHeight - scrollTop) === clientHeight) {
      if (page < lastPage) setPage((prev) => prev + 1);
    }
  };

  return (
    <IonPage className="common-pages">
      <IonContent fullscreen scrollEvents={true} onIonScroll={handleScroll}>
        <Navbar />
        <div className="page-title-container">
          <div className="page-title justify-space-between">
            <div className="flex justify-left">
              <span className="font-big text-aster-red bold">
                <IoMdPaper />
              </span>&nbsp;
              <span className="title-text primary-font font-1-2 text-aster-red bold">
                Bem-vindo
              </span>
            </div>
          </div>
        </div>
        <IonGrid>
          {paginaInicial && (
            <>
              <IonRow>
                <IonCol size="12" sizeMd="10" sizeXl="8" offsetMd="1" offsetXl="2">
                  <div
                    dangerouslySetInnerHTML={{ __html: paginaInicial.texto }}
                  />
                </IonCol>
              </IonRow>
            </>
          )}
          <IonRow>
            <IonCol size="12" sizeMd="10" sizeXl="8" offsetMd="1" offsetXl="2">
              <ul className="container-doc-card">
                {paginas.map((pagina, index) => {
                  return (
                    <li key={index}>
                      <CardPagina id={pagina.id} titulo={pagina.titulo} />
                    </li>
                  );
                })}
              </ul>
              {paginas.length === 0 && !loading && (
                <h1 className="primary-font text-aster-red font-medium ion-text-center">
                  Nenhum resultado encontrado.
                </h1>
              )}
              {loading && (
                <h5 className="primary-font text-aster-red font-1-3 ion-text-center">
                  <AiOutlineLoading3Quarters className="spin" />
                </h5>
              )}
              {error && (
                <>
                  <h5 className="primary-font text-aster-red font-1-4 ion-text-center">
                    <FaRegSadTear />
                  </h5>
                  <h5 className="primary-font text-aster-red font-medium ion-text-center">
                    Oops! Houve um erro no carregamento.
                  </h5>
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PaginasPage;
